 

  export const validateFormData = (formData) => {
    const errors = {};
   
    const lettersRegex = /^[A-Za-z\s]+$/;
  
    if (!formData.quality || !formData.quality.trim()) {
      errors.quality = "Quality field is required";
    } else if (!lettersRegex.test(formData.quality.trim())) {
      errors.quality = "Quality field should contain only letters";
    }
  
    if (!formData.status || !formData.status.trim()) {
      errors.status = "Status is required";
    }
  
    if (Object.keys(errors).length > 0) {
      return { isValid: false, errors };
    }
  
    return { isValid: true, errors: {} };
  };




   

   