import { DEFAULT_ID, DEL_SOURCE_BOOK, FETCH_ASSIGNED_PROPERTY_TASK, FETCH_SOURCE_BOOK, PROPERTY_TASK_BY_STAFFID } from "../../Actions/SourceBook/sourceBookAction"

const initialState ={
    SourceBookReport: [],
    SourceDefaultID: null,
    PropertyTask:[],
    AllAssignedTaskData:[]
}





const SourceBookReducer = (state = initialState,action) =>{
 switch(action.type){
    case FETCH_SOURCE_BOOK:
        const dataWithSno = action.payload.map((item,index)=>({
            ...item,
            sno: (index + 1).toString()
        }));
        return{
            ...state,
            SourceBookReport:dataWithSno
        }

    case DEL_SOURCE_BOOK:
        const updateData = state.SourceBookReport.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updateData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }));
        return{
            ...state,
            SourceBookReport:updatedDataWithSno 
        }


        case DEFAULT_ID:
            return {
                ...state,
                SourceDefaultID: action.payload
            }; 

    
            case PROPERTY_TASK_BY_STAFFID:
                const dataWithSnos = action.payload.map((item,index)=>({
                    ...item,
                    sno: (index + 1).toString()
                }));
                return{
                    ...state,
                    PropertyTask:dataWithSnos
                } 

            case FETCH_ASSIGNED_PROPERTY_TASK:
                const dataWithSnoss = action.payload.map((item,index)=>({
                    ...item,
                    sno: (index + 1).toString()
                }));
                return{
                    ...state,
                    AllAssignedTaskData:dataWithSnoss
                }


        default:
            return state;
 }
}
 
export default SourceBookReducer;
 
