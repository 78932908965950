import React, { useEffect, useState } from "react";
import Toast from "../../Utils/Toast";

const BulkUploadEdit = ({ isOpen, closeModal, editData, updateNewData }) => {
  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [formData, setFormData] = useState({
    "Project ID": "",
    "Project sourced by": "",
    "Project Status":"",
    "Date & Time": "",
    "Source of Project":"",
    "Owned by":" ",
    "Company Name": "",
    "Contact Details":"",
    "Owner Ranking":"",
    "Project Identified for":"",
    "Project Type" :"",
    "Approval Type" : "",
    "Approval No" : "",
    "Project Launch Year":"",
    "Project Condition" :"",
    "Project Name":"",
    "Village" : "",
    "Project Location":"",
    "Taluk" : "",
    "District" : "",
    "Enter Village Name Manually":"",
    "Location Address":" ",
    "Village/Taluk/District": "",
    "Total Acres": "",
    "Total Plots":"",
    "Total Saleable Plots": "",
    "Cost":"",
    "Total Blocks": "",
    "A Block": "",
    "B Block":"",
    "C Block":"",
    "D Block":"",
    "E Block":"",
    "Corner Plot":"",
    "Guideline Value":"",
    "Documentation & Patta Charges":"",
    "Computer Fees":"",
    "Booking Advance":"",
    "Bank Details":"",
    "Survey No" : "",
    "Capture Photos":"",
    "Google Drive Photos & Videos": "",
    "Geo Link": "",
    "Location Address":"",
    "Remarks":"",
    "User":"",
    "Note":"",
    "UID AB":"",
    "Unique Code":"",
    "Project Location":"",
    "Distance from AH (km)":"",
    "Distance from NH (km)":"",
    "Distance from SH (km)":"",
    "Distance from MDR (km)":"",
    "Distance from ODR (km)":"",
    "Distance from VR (km)":"",
    "Have you checked the 4 Direction Developments?":"",
    "List down the Nearest Towns with Km" :"",
    "List down the Nearest Bus, Railways & Airport with Km":"",
    "List down the Nearest Hospitals with KM":"",
    "List down the Nearest Bank with KM":"",
    "List down the Nearest SIPCOT / SEZ with KM":"",
    "List down the Nearest Tourism Attraction with KM":"",
    "Project Data Status":""
  });

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitData = (e) => {
    e.preventDefault();
    Toast({ message: "Updated Successfully", type: "success" }); 
    updateNewData(formData);
    closeModal();
  };

  const handleCancel =(e) =>{
    e.preventDefault();
    closeModal();
    setFormData({
        "Project ID": "",
        "Project sourced by": "",
        "Project Status":"",
        "Date & Time": "",
        "Source of Project":"",
        "Owned by":" ",
        "Company Name": "",
        "Contact Details":"",
        "Owner Ranking":"",
        "Project Identified for":"",
        "Project Type" :"",
        "Approval Type" : "",
        "Approval No" : "",
        "Project Launch Year":"",
        "Project Condition" :"",
        "Project Name":"",
        "Village" : "",
        "Project Location":"",
        "Taluk" : "",
        "District" : "",
        "Enter Village Name Manually":"",
        "Location Address":" ",
        "Village/Taluk/District": "",
        "Total Acres": "",
        "Total Plots":"",
        "Total Saleable Plots": "",
        "Cost":"",
        "Total Blocks": "",
        "A Block": "",
        "B Block":"",
        "C Block":"",
        "D Block":"",
        "E Block":"",
        "Corner Plot":"",
        "Guideline Value":"",
        "Documentation & Patta Charges":"",
        "Computer Fees":"",
        "Booking Advance":"",
        "Bank Details":"",
        "Survey No" :"",
        "Capture Photos":"",
        "Google Drive Photos & Videos": "",
        "Geo Link": "",
        "Location Address":"",
        "Remarks":"",
        "User":"",
        "Note":"",
        "UID AB":"",
        "Unique Code":"",
        "Project Location":"",
        "Distance from AH (km)":"",
        "Distance from NH (km)":"",
        "Distance from SH (km)":"",
        "Distance from MDR (km)":"",
        "Distance from ODR (km)":"",
        "Distance from VR (km)":"",
        "Have you checked the 4 Direction Developments?":"",
        "List down the Nearest Towns with Km" :"",
        "List down the Nearest Bus, Railways & Airport with Km":"",
        "List down the Nearest Hospitals with KM":"",
        "List down the Nearest Bank with KM":"",
        "List down the Nearest SIPCOT / SEZ with KM":"",
        "List down the Nearest Tourism Attraction with KM":"",
        "Project Data Status":""       
    })
  }

  return (
    <div
      className={`modal modal-overlay ${isOpen ? "d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <h4 className="page_subheading m-3">Update Bulk Upload data</h4>
            <button
              type="button"
              className="close closebutton"
              onClick={handleCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card">
            <div className="card-heaer mb-3">
              <div className="d-flex bottom1 justify-content-center">
                <div>
                  <nav className="nav">
                    <a
                      className={`nav-link link1 ${
                        step === 1 ? "active1" : ""
                      }`}
                      href="#"
                      onClick={() => setStep(1)}
                    >
                      Group 1
                    </a>
                    <a
                      className={`nav-link link1 ${
                        step === 2 ? "active1" : ""
                      }`}
                      href="#"
                      onClick={() => setStep(2)}
                    >
                      Group 2
                    </a>
                    <a
                      className={`nav-link link1 ${
                        step === 3 ? "active1" : ""
                      }`}
                      href="#"
                      onClick={() => setStep(3)}
                    >
                      Group 3
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="card-body p-3">
              {step === 1 && (
                <div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Project ID</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project ID"
                              readOnly
                              value={formData["Project ID"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project sourced by
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project sourced by"
                              value={formData["Project sourced by"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Project Status</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Status"
                              value={formData["Project Status"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Date & Time</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="date"
                              className="form-control"
                              name="Date & Time"
                              value={formData["Date & Time"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Source of Project
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Source of Project" 
                              value={formData["Source of Project"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Owned by</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Owned by" 
                              value={formData["Owned by"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Company Name</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Company Name" 
                              value={formData["Company Name"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Contact Details
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Contact Details" 
                              value={formData["Contact Details"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Owner Ranking</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Owner Ranking" 
                              value={formData["Owner Ranking"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project Identified for
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Identified for" 
                              value={formData["Project Identified for"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Project Type</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Type" 
                              value={formData["Project Type"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Approval No</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Approval No" 
                              value={formData["Approval No"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project Condition
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Condition" 
                              value={formData["Project Condition"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Project Name</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Name" 
                              value={formData["Project Name"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Village</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Village" 
                              value={formData["Village"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Taluk</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Taluk" 
                              value={formData["Taluk"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">District</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="District" 
                              value={formData["District"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Enter Village Name Manually
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Enter Village Name Manually" 
                              value={formData["Enter Village Name Manually"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project Location
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Location" 
                              value={formData["Project Location"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

          

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                               Survey No
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Survey No" 
                              value={formData["Survey No"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Capture Photos</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Capture Photos" 
                              value={formData["Capture Photos"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Google Drive Photos & Videos
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Google Drive Photos & Videos" 
                              value={formData["Google Drive Photos & Videos"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

              

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Location Address
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Location Address" 
                              value={formData["Location Address"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


        

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Remarks</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Remarks" 
                              value={formData["Remarks"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">User</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="User" 
                              value={formData["User"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end py-3 px-3">
                    <button className="btn1 me-1">Clear</button>
                    <button className="btn1" onClick={nextStep}>
                      Next
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project Launch Year
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Launch Year" 
                              value={formData["Project Launch Year"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Total Acres</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Acres" 
                              value={formData["Total Acres"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Total Plots</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Plots" 
                              value={formData["Total Plots"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Total Saleable Plots
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Saleable Plots" 
                              value={formData["Total Saleable Plots"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Total Sqft</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Sqft" 
                              value={formData["Total Sqft"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Total Saleable Sqft
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Saleable Sqft" 
                              value={formData["Total Saleable Sqft"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Marketing Price
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Marketing Price" 
                              value={formData["Marketing Price"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Selling Price</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Selling Price" 
                              value={formData["Selling Price"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Total Blocks</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Total Blocks" 
                              value={formData["Total Blocks"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">A Block</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="A Block" 
                              value={formData["A Block"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">B Block</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="B Block" 
                              value={formData["B Block"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">C Block</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="C Block" 
                              value={formData["C Block"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">D Block</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="D Block" 
                              value={formData["D Block"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">E Block</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="E Block" 
                              value={formData["E Block"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Corner Plot</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Corner Plot" 
                              value={formData["Corner Plot"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Note</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Note" 
                              value={formData["Note"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">UID AB</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="UID AB" 
                              value={formData["UID AB"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Unique Code</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Unique Code" 
                              value={formData["Unique Code"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-end py-3 px-3">
                    <button className="btn1 me-1" onClick={prevStep}>
                      Previous
                    </button>
                    <button className="btn1" onClick={nextStep}>
                      next
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Guideline Value
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Guideline Value" 
                              value={formData["Guideline Value"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Documentation & Patta Charges
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Documentation & Patta Charges" 
                              value={formData["Documentation & Patta Charges"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Booking Advance
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Booking Advance" 
                              value={formData["Booking Advance"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Computer Fees</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Computer Fees" 
                              value={formData["Computer Fees"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">Bank Details</label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Bank Details" 
                              value={formData["Bank Details"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project is located on
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project is located on" 
                              value={formData["Project is located on"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from AH (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from AH (km)" 
                              value={formData["Distance from AH (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from NH (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from NH (km)" 
                              value={formData["Distance from NH (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from SH (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from SH (km)" 
                              value={formData["Distance from SH (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from MDR (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from MDR (km)" 
                              value={formData["Distance from MDR (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from ODR (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from ODR (km)" 
                              value={formData["Distance from ODR (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Distance from VR (km)
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="number"
                              className="form-control"
                              name="Distance from VR (km)" 
                              value={formData["Distance from VR (km)"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Have you checked the 4 Direction Developments?
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Have you checked the 4 Direction Developments?" 
                              value={formData["Have you checked the 4 Direction Developments?"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Towns with Km
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Towns with Km" 
                              value={formData["List down the Nearest Towns with Km"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Bus, Railways & Airport with
                              Km
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Bus, Railways & Airport with Km" 
                              value={formData["List down the Nearest Bus, Railways & Airport with Km"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Schools & Colleges with KM
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Schools & Colleges with KM" 
                              value={formData["List down the Nearest Schools & Colleges with KM"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Hospitals with KM
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Hospitals with KM" 
                              value={formData["List down the Nearest Hospitals with KM"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Bank with KM
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Bank with KM" 
                              value={formData["List down the Nearest Bank with KM"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest SIPCOT / SEZ with KM
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest SIPCOT / SEZ with KM" 
                              value={formData["List down the Nearest SIPCOT / SEZ with KM"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              List down the Nearest Tourism Attraction with KM
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="List down the Nearest Tourism Attraction with KM" 
                              value={formData["List down the Nearest Tourism Attraction with KM"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group mt-3">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Project Data Status
                            </label>
                          </div>
                          <div className="col-7">
                            <input
                              type="text"
                              className="form-control"
                              name="Project Data Status" 
                              value={formData["Project Data Status"]}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-end py-3 px-3">
                    <button className="btn1 me-1" onClick={prevStep}>
                      Previous
                    </button>
                    <button className="btn1" onClick={submitData}>
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadEdit;
