import React, { useEffect, useState, useRef  } from "react";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StateEdit from "./StateEdit";
import * as XLSX from 'xlsx';
import Moment from 'moment';
// import { format } from 'date-fns';
import { SearchData } from "../../../Utils/Search";
import ExportButton from "../../../Utils/ExportButton";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addState,
  deleteState,
  fetchState,
} from "../../../Redux/Actions/MasterPage/StateAction"; 
import Toast from "../../../Utils/Toast";
 import { validateFormData, validateFormDataBulk } from "./stateValidation";
import customStyle from "../../../Utils/tableStyle";

function State() {
 
  const updata = useRef(null);
  const StateNameData = useSelector(
    (state) => state.State.StateNameData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchState()); 
  }, [dispatch]);

  const [formData, setFormData] = useState({
    state_name: " ",
    status: "Enable",
  });

  const [stateName,setStateName] = useState()

  const newName =stateName ?(stateName.map(data => ({
    state_name: data.State,
    status: "Enable"
  }))) :("");
  

  // console.log("newName",newName )
  const stateChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
 


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const binaryStr = await file.arrayBuffer();
    const workbook = XLSX.read(binaryStr, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);
    setStateName(jsonData)
};

const [errorsBulk, setErrorsBulk] = useState();
 
const bulkSubmit = (e) => {
  const result = validateFormDataBulk(newName[0])
  if(result.isValid){
    setErrorsBulk("")
    dispatch(addState(newName)); 
    Toast({ message: "Added successfully", type: "success" });
    updata.current.value = '';
    setStateName([]);
  }else{
    setErrorsBulk(result.errorMessage)
  } 
}


const [errors, setErrors] = useState({});

  const state_nameubmit = (e) => {
    e.preventDefault();  
  const stateSubmitData = formData
    const validationResult = validateFormData(formData) 
    if (validationResult.isValid) { 
      dispatch(addState(stateSubmitData)); 
      Toast({ message: "Added successfully", type: "success" });
      setErrors("") 
      setFormData({ state_name: "", status: "Enable" })
    } else { 
      setErrors(validationResult.errors);
    }
  };

  const columns = [
    {
      name: "S.no",
      cell: (row) => row.sno,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>Moment(row.createdat).format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

  

  

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = ["sno", "createdat", "state_name", "status"];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(StateNameData, filterText, searchColumns);
  /////////////////////////////////////

  const [editData,setEditData] = useState();

  const handleEdit = (row) => {
    console.log("Editing row:", row);
    setEditData(row);
  };

  const handleDelete = (row) => {
    console.log("Deleting row:", row.id); 
    const shouldDelete = window.confirm("Are you sure you want to delete?");    
    if (shouldDelete) {
      try { 
          dispatch(deleteState(row.id)); 
        Toast({ message: "Successfully Deleted", type: "error" }); 
      } catch (error) {  
        console.error("Error deleting item:", error);
      }
    } 
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Topbar />
      
      <StateEdit isOpen={isModalOpen} closeModal={closeModal} editData={editData}/> 
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <Tabs
                  defaultActiveKey="tab1"
                  id="fill-tab-example"
                  className=" "
                  fill
                >
                  <Tab eventKey="tab1" title="Add Single State ">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label htmlFor="lastName" className="form-label">
                              State
                            </label>
                            <input
                              type="text"
                              name="state_name"
                              value={formData.state_name}
                            
                              onChange={stateChange}
                              className="form-control"
                              id="lastName" autoComplete="off"
                            />
                                 {errors.state_name && <div className="validation_msg">{errors.state_name}</div>}
                          </div>
                        </div>

                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="inputState">
                            Status
                          </label>
                          <select 
                          id="inputState" name="status"
                          className="form-select"
                          value={formData.status}
                         
                          onChange={stateChange}>
                            <option value="Enable">Enable</option>
                            <option value="Disable">Disable</option>
                          </select>
                          {errors.status && <div className="validation_msg">{errors.status}</div>}
                        </div>
 
                      </form>
                    </div>

                    
                    <div className="text-end py-3 px-3">
                      <button  className="  btn1 me-1" onClick={()=>{
                      setFormData({ state_name: "", status: "Enable" });
                        setErrors({});
                      }}>Clear</button>
                      <button  onClick={state_nameubmit}className="  btn1">Add</button>
                    </div>
                  </Tab>
                  <Tab eventKey="longer-tab" title="Add Multiple State">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label htmlFor="lastName" className="form-label">
                              Upload the excel file
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="lastName"ref={updata}
                              onChange={handleFileUpload}
                             
                         
                            />
                              {errorsBulk && <div className="validation_msg">{errorsBulk}</div>}
                          </div>
                        </div>
                  
                        <div className="col-md-12 d-flex py-3 px-3">
                        <ExportButton
                        columns={[State]}
                        data={[]}
                        filename={"state.csv"}
                        type={"Format"}
                      />
                      <div style={{marginLeft:"auto"}}>
                          <button className="btn1 me-1" type="button">Clear</button>
                          <button onClick={bulkSubmit} type="button" className="btn1">Add</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">State Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={StateNameData}
                        filename={"state.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>
                    <DataTable
                      persistTableHead={true}
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      // selectableRows 
                      fixedHeader
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactTooltip
        id="edit"
        place="bottom"
        content="Edit"
        style={{ fontSize: "10px" }}
      />
      <ReactTooltip
        id="delete"
        place="bottom"
        content="Delete"
        style={{ fontSize: "10px" }}
      />
      <Footerbar />
    </>
  );
}

export default State;
