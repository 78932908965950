import React, { useState, useEffect } from "react";  
import { validateFormData } from "./QualityValidation";
import Toast from "../../../Utils/Toast";
import { useDispatch } from "react-redux";
import { updateQuality } from "../../../Redux/Actions/MasterPage/QualityAction";

const QualityCreationEdit = ({ isOpen, closeModal, editData }) => {

  const dispatch = useDispatch();


  const [formData, setFormData] = useState({
    quality: "",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
   

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleCancel = () => {
    setFormData(editData);
    closeModal();
  };


  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();  
    const validationResult = validateFormData(formData);
    if (validationResult.isValid) {
      setErrors("");
      closeModal();
      setFormData(editData);
      Toast({ message: "Updated Successfully", type: "success" });
      await dispatch(updateQuality(formData));  
    } else {
      setErrors(validationResult.errors);
    }
  };

  return (
    <div
      className={`modal modal-overlay ${isOpen ? "d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <h4 className="page_subheading m-3">Update Quality Creation</h4>
            <button
              type="button"
              className="close closebutton"
              onClick={handleCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body p-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3 ">
                  <label htmlFor="lastName" className="form-label">
                    Quality  
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="quality"
                    value={formData.quality}
                    onChange={handleChange}
                  />
                   {errors.quality && <div className="validation_msg">{errors.quality}</div>} 
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="inputState">
                  Status
                </label>
                <select
                  className="form-select"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option   value="">
                    --Select Status--
                  </option>
                  <option value="Enable">Enable</option>
                  <option value="Disable">Disable</option>
                </select>
                {errors.status && <div className="validation_msg">{errors.status}</div>}
              </div>

           

              <div className="text-end py-3 px-3">
                <button
                  type="button"
                  className="btn1 me-1"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn1">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityCreationEdit;


