import {
    FETCH_GROUP_TYPE,
    DEL_GROUP_TYPE,
  } from "../../Actions/MasterPage/GroupTypeAction";

  
const initialState = {
    GroupTypeData: [],
  };
  
  const GroupTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GROUP_TYPE:
        const dataWithSno = action.payload.map((item, index) => ({
          ...item,
          sno: (index + 1).toString()
        }));
        return {
          ...state,
          GroupTypeData: dataWithSno,
        };
        
      case DEL_GROUP_TYPE:
        const updatedData = state.GroupTypeData.filter(
          (GroupType) => GroupType.id !== action.payload
        );
        const updatedDataWithSno = updatedData.map((item, index) => ({
          ...item,
          sno: (index + 1).toString()
        }));
        return {
          ...state,
          GroupTypeData: updatedDataWithSno,
        };
  
      default:
        return state;
    }
  };
  
  export default GroupTypeReducer;
  