import { DEFAULT_ID, DEL_SOCIETY, FETCH_ASSIGNED_SOCIETY_TASK, FETCH_SOCIETY, SOCIETY_TASK_BY_STAFFID } from "../../Actions/Society/societyAction";

 
const initialState ={
    SocietyReport: [],
    SocietyDefaultID: null,
    SocityTask:[],
    AllAssignedTaskData:[]
}

const SocietyReducer = (state = initialState,action) =>{
 switch(action.type){
    case FETCH_SOCIETY:
        const dataWithSno = action.payload.map((item,index)=>({
            ...item,
            sno: (index + 1).toString()
        }));
        return{
            ...state,
            SocietyReport:dataWithSno
        }

    case DEL_SOCIETY:
        const updateData = state.SocietyReport.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updateData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }));
        return{
            ...state,
            SocietyReport:updatedDataWithSno 
        }


        case DEFAULT_ID:
            return {
                ...state,
                SocietyDefaultID: action.payload
            }; 

        
        case SOCIETY_TASK_BY_STAFFID:
            const dataWithSnos = action.payload.map((item,index)=>({
                ...item,
                sno: (index + 1).toString()
            }));
            return{
                ...state,
                SocityTask:dataWithSnos
            }

         
        case FETCH_ASSIGNED_SOCIETY_TASK:
            const dataWithSnoss = action.payload.map((item,index)=>({
                ...item,
                sno: (index + 1).toString()
            }));
            return{
                ...state,
                AllAssignedTaskData:dataWithSnoss
            }


        default:
            return state;
 }
}

export default SocietyReducer;


 