import { DEL_STATUS, FETCH_STATUS } from "../../Actions/MasterPage/StatusAction";

 
const initialState = {
    statusData: []
};

const StatusReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_STATUS:
            const dataWithSno = action.payload.map((item, index) => ({
                ...item,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                statusData: dataWithSno
            }; 
        case DEL_STATUS:
            const updatedData = state.statusData.filter(statusData => statusData.id !== action.payload); 
            const updatedDataWithSno = updatedData.map((item, index) => ({
                ...item,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                statusData: updatedDataWithSno
            };
        default:
            return state;
    }
};

export default StatusReducer;
