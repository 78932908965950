import axios from "axios";
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_GROUP_TYPE = "FETCH_GROUP_TYPE";
export const DEL_GROUP_TYPE = "DEL_GROUP_TYPE";

const fetchGroupTypeSuccess = (propertyTypeData) => ({
  type: FETCH_GROUP_TYPE,
  payload: propertyTypeData,
});

const deleteGroupTypeSuccess = (id) => ({
  type: DEL_GROUP_TYPE,
  payload: id,
});

// Api
export const fetchGroupType = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/group`);
      dispatch(fetchGroupTypeSuccess(response.data));
    } catch (error) {
      console.error("Error fetching Group types:", error);
    }
  };
};

export const addGroupType = (insertData) => {
  return async (dispatch) => {
    try {
      await axios.post(`${API_BASE_URL}/group`, insertData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(fetchGroupType());
    } catch (error) {
      console.error("Error adding Group types:", error);
    }
  };
};

export const deleteGroupType = (id) => {
  return async (dispatch) => {
    try {
      await axios.delete(`${API_BASE_URL}/group/${id}`);
      dispatch(deleteGroupTypeSuccess(id));
    } catch (error) {
      console.error("Error deleting Group types:", error);
    }
  };
};

export const updateGroupType = (updateData) => {
  return async (dispatch) => {
    try {
      await axios.put(`${API_BASE_URL}/group/${updateData.id}`, updateData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(fetchGroupType());
    } catch (error) {
      console.error("Error updating Group types:", error);
    }
  };
};
