import { DEL_TALUK, FETCH_TALUK } from "../../Actions/MasterPage/TalukAction"

const initialState ={
    TalukData : []
}

const talukReducer = (state = initialState , action) => {
    switch(action.type){
        case FETCH_TALUK:
            const dataWithSno = action.payload.map((data,index)=>({ 
                ...data,
                sno: (index + 1).toString()
            }));
        return{
            TalukData : dataWithSno
        }

    case DEL_TALUK:
        const updatedData = state.TalukData.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updatedData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }))
        return{
            ...state,
            TalukData : updatedDataWithSno
        }
    
       

    default:
        return state;
    }
}

export default talukReducer;