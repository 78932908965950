import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ element: Component }) => {
  const isAuthenticated = sessionStorage.getItem('login_status'); 
  const path = isAuthenticated === "admin" ? "/dashboard" : "/staff_dashboard" 
  return isAuthenticated ? <Navigate to={path} /> : <Component />;
};

export default PublicRoute;
