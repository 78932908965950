import React, { useEffect, useRef, useState } from "react";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PincodeEdit from "./pincodeEdit";
import { SearchData } from "../../../Utils/Search";
import ExportButton from "../../../Utils/ExportButton";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addPincode,
  deletePincode,
  fetchPincode,
} from "../../../Redux/Actions/MasterPage/PincodeAction";
import customStyle from "../../../Utils/tableStyle";
import StateDropDown from "../../../Utils/SelectDropDown/StateDropDown";
import DistrictDropDown from "../../../Utils/SelectDropDown/DistrictDropDown";
import TalukDropDown from "../../../Utils/SelectDropDown/TalukDropDown";
import VillageDropDown from "../../../Utils/SelectDropDown/VillageDropDown";
import { PincodeValidateFormData, PincodeValidateFormDatas } from "./Validation";
import Toast from "../../../Utils/Toast";
import { DeleteById } from "../../../Utils/DeleteById";
import ExcelFileUpload from "../../../Utils/ExcelFileUpload";

function Pincode() {
  // get pincode all data
  const pincodeData = useSelector((state) => state.Pincode.PincodeData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPincode());
  }, [dispatch]);

  // add new data
  const [formData, setFormData] = useState({
    pin_state: " ",
    pin_district: " ",
    pin_taluk: " ",
    pin_village: "",
    pincode: "",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // set dropdown
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState(null);

  const handleStateSelect = (state) => {
    setSelectedState(state);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setSelectedVillage(null);
  };

  const handleDistrictSelect = (district) => {
    setSelectedDistrict(district);
    setSelectedTaluk(null);
    setSelectedVillage(null);
  };

  const handleTalukSelect = (taluk) => {
    setSelectedTaluk(taluk);
    setSelectedVillage(null);
  };

  const handleVillageSelect = (village) => {
    setSelectedVillage(village);
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      pin_state: selectedState ? selectedState.value : "",
      pin_district: selectedDistrict ? selectedDistrict.value : "",
      pin_taluk: selectedTaluk ? selectedTaluk.value : "",
      pin_village: selectedVillage ? selectedVillage.value : "",
    }));

    setBulkFormData((prevState) => ({
      ...prevState,
      pin_state: selectedState ? selectedState.value : "",
      pin_district: selectedDistrict ? selectedDistrict.value : "",
      pin_taluk: selectedTaluk ? selectedTaluk.value : "",
      pin_village: selectedVillage ? selectedVillage.value : "",
    }));
  }, [selectedState, selectedDistrict, selectedTaluk, selectedVillage]);

  // submit

  const [errors, setErrors] = useState({});  //validation messages 
  const handleSubmit = (e) => {
    e.preventDefault();
    const result = PincodeValidateFormData(formData);
    if (result.isValid) {
      Toast({ message: "Added successfully", type: "success" });
      handleResetSelected(); 
      dispatch(addPincode([formData]));
    } else {
      setErrors(result.errors);
    }
  };

  const handleResetSelected = () => {
    setSelectedState(null);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setSelectedVillage(null);
    setFormData({ pincode: "", status: "" });
    setErrors("");
    setErrorMessageBulk("");
  };

  // delete
  const handleDelete = (row) => {
    DeleteById(row.id, deletePincode, dispatch);
  };

  // edit
  const [editData, setEditData] = useState();
  const handleEdit = (row) => {
    setEditData(row);
  };

  // bulkUpload
  const fileUploadRef = useRef(null);
  const [excelData, setExcelData] = useState([]);
  const [bulkFormData, setBulkFormData] = useState({
    pin_state: " ",
    pin_district: " ",
    pin_taluk: " ",
    pin_village: "",
    pincode: "",
    status: "Enable",
  });
  // convert json format
  const bulkData = excelData.map((data) => ({
    pin_state: bulkFormData.pin_state,
    pin_district: bulkFormData.pin_district,
    pin_taluk: bulkFormData.pin_taluk,
    pin_village: bulkFormData.pin_village,
    pincode: data.Pincode,
    status: bulkFormData.status,
  }));

  const [errorMessageBulk, setErrorMessageBulk] = useState(""); //validation messages
  console.log("ghtyu",errorMessageBulk)

  const BulkSubmit = (e) => {
    e.preventDefault();
    const validationResult = PincodeValidateFormDatas(bulkData[0]);
    if (validationResult.isValid) {
      fileUploadRef.current.value = "";
      Toast({ message: "Added successfully", type: "success" });
      setExcelData([]);
      handleResetSelected();
      dispatch(addPincode(bulkData));
    } else {
      setErrorMessageBulk(validationResult.errorMessage);
    }
  };

  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdat,
      sortable: true,
      wrap: true,
    },
    {
      name: "State",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row.district,
      sortable: true,
      wrap: true,
    },
    {
      name: "Taluk",
      selector: (row) => row.taluk_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Village",
      selector: (row) => row.village_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn  btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];
  const formatColumn = [
    {
      name: "Pincode",
      selector: (row) => row.sno,
    },
  ];

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [
    "sno",
    "createdat",
    "state_name",
    "district",
    "taluk_name",
    "village_name",
    "pincode",
    "status",
  ];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(pincodeData, filterText, searchColumns);
  /////////////////////////////////////

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Topbar />
      <PincodeEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
      />
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <Tabs
                  defaultActiveKey="tab1"
                  id="fill-tab-example"
                  className=" "
                  fill
                >
                  <Tab eventKey="tab1" title="Add Single Pincode">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label className="form-label" htmlFor="inputState">
                              State
                            </label>
                            <StateDropDown
                              onSelect={handleStateSelect}
                              selectedState={selectedState}
                            />
                                {errors.pin_state && <div className="validation_msg">{errors.pin_state}</div>}
                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="inputState">
                              District
                            </label>
                            <DistrictDropDown
                              onSelect={handleDistrictSelect}
                              selectedDistrict={selectedDistrict}
                              filter={selectedState}
                            />
                                {errors.pin_district && <div className="validation_msg">{errors.pin_district}</div>} 
                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="inputState">
                              Taluk
                            </label>
                            <TalukDropDown
                              onSelect={handleTalukSelect}
                              selectedTaluk={selectedTaluk}
                              filter={selectedDistrict}
                            />
                                {errors.pin_taluk && <div className="validation_msg">{errors.pin_taluk}</div>}

                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label" htmlFor="inputState">
                              Village
                            </label>
                            <VillageDropDown
                              onSelect={handleVillageSelect}
                              selectedVillage={selectedVillage}
                              filter={selectedTaluk}
                            />
                             {errors.pin_village && <div className="validation_msg">{errors.pin_village}</div>} 
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 ">
                          <label htmlFor="lastName" className="form-label">
                            Pincode
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                          />
                           {errors.pincode && <div className="validation_msg">{errors.pincode}</div>}

                        </div>

                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="inputState">
                            Status
                          </label>
                          <select
                            id="inputState"
                            className="form-select"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                          >
                            <option disable value="">
                              --Select Status--
                            </option>
                            <option value="Enable">Enable</option>
                            <option value="Disable">Disable</option>
                          </select>
                          {errors.status && <div className="validation_msg">{errors.status}</div>}

                        </div>

                      
                        <div className="text-end py-3 px-3">
                          <button
                            className="btn1 me-1"
                            type="button"
                            onClick={handleResetSelected}
                          >
                            Clear
                          </button>
                          <button className="btn1" onClick={handleSubmit}>
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </Tab>
                  <Tab eventKey="longer-tab" title="Add Multiple Pincode">
                    <div className="card-body">
                      <form>
                        <div className="col-md-12 mb-3 ">
                          <label className="form-label" htmlFor="inputState">
                            State
                          </label>
                          <StateDropDown
                            onSelect={handleStateSelect}
                            selectedState={selectedState}
                          />
                        </div>

                        <div className="col-md-12 mb-3 ">
                          <label className="form-label" htmlFor="inputState">
                            District
                          </label>
                          <DistrictDropDown
                            onSelect={handleDistrictSelect}
                            selectedDistrict={selectedDistrict}
                            filter={selectedState}
                          />
                        </div>

                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="inputState">
                            Taluk
                          </label>
                          <TalukDropDown
                            onSelect={handleTalukSelect}
                            selectedTaluk={selectedTaluk}
                            filter={selectedDistrict}
                          />
                        </div>

                        <div className="mb-3 col-md-12">
                          <label className="form-label" htmlFor="inputState">
                            Village
                          </label>
                          <VillageDropDown
                            onSelect={handleVillageSelect}
                            selectedVillage={selectedVillage}
                            filter={selectedTaluk}
                          />
                        </div>

                        <div className="row">
                          <div className="col-md-12 mb-3 ">
                            <label htmlFor="lastName" className="form-label">
                              Upload the excel file
                            </label>
                            <ExcelFileUpload
                              setExcelData={setExcelData}
                              fileInputRef={fileUploadRef}
                            />
                          </div>
                        </div>

                        {errorMessageBulk && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessageBulk}
                          </div>
                        )}

                        <div className="col-md-12 d-flex py-3 px-3">
                          <ExportButton
                            columns={formatColumn}
                            data={[]}
                            filename={"Pincode.csv"}
                            type={"Format"}
                          />
                          <div style={{ marginLeft: "auto" }}>
                            <button
                              className="btn1 me-1"
                              type="button"
                              onClick={handleResetSelected}
                            >
                              Clear
                            </button>
                            <button className="btn1" onClick={BulkSubmit}>
                              Add
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={pincodeData}
                        filename={"pincode.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      // selectableRows
                      fixedHeader
                      persistTableHead={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactTooltip
        id="edit"
        place="bottom"
        content="Edit"
        style={{ fontSize: "10px" }}
      />
      <ReactTooltip
        id="delete"
        place="bottom"
        content="Delete"
        style={{ fontSize: "10px" }}
      />
      <Footerbar />
    </>
  );
}

export default Pincode;
