import axios from "axios";
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_PROPERTY_DOC = "FETCH_PROPERTY_DOC";  
export const DEL_PROPERTY_DOC = "DEL_PROPERTY_DOC";  



const fetchPropertyDocumentSuccess = (PropertyDocumentData) =>{
    return {
        type: FETCH_PROPERTY_DOC,
        payload: PropertyDocumentData
    }
}

const deletePropertyDocumentSuccess = (id) => {
    return {
        type: DEL_PROPERTY_DOC,
        payload: id
    }
}

 

export const fetchPropertyDocument = () => {
    return async (dispatch) => {
        try{
            const response = await axios.get(`${API_BASE_URL}/propertydoc`);
            dispatch(fetchPropertyDocumentSuccess(response.data));

        }catch(error){
             console.error('Error Fetching Property Document:',error)
        }
    }
}

export const addPropertyDocument = (insertData) => {
    return async (dispatch) => {
        try{
              await axios.post(`${API_BASE_URL}/propertydoc`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchPropertyDocument())
        }catch(error){
             console.error('Error Adding Property Document:',error)
        }
    }
}

export const deletePropertyDocument = (id) => {
    return async (dispatch) => {
        try{
            await axios.delete(`${API_BASE_URL}/propertydoc/${id}`); 
            dispatch(deletePropertyDocumentSuccess(id));
        }catch(error){
            console.error('Error deleting property Documents:', error);
        }
    }
}

export const updatePropertyDocument = (updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/propertydoc/${updateData.id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchPropertyDocument())
        }catch(error){
             console.error('Error Updating Property Document:',error)
        }
    }
}