import axios from "axios";
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_SRO_Details = "FETCH_SRO_Details";  
export const DEL_SRO_Details = "DEL_SRO_Details";  

const fetchSRODetailsSuccess = (SRODetailsData) =>{
    return {
        type: FETCH_SRO_Details,
        payload: SRODetailsData
    }
}

const deleteSRODetailsSuccess = (id) => {
    return {
        type: DEL_SRO_Details,
        payload: id
    }
}

 

export const fetchSRODetails = () => {
    return async (dispatch) => {
        try{
            const response = await axios.get(`${API_BASE_URL}/srodetails`);
            dispatch(fetchSRODetailsSuccess(response.data));
console.log(response)
        }catch(error){
             console.error('Error Fetching SRODetails:',error)
        }
    }
}

export const addSRODetails = (insertData) => {
    return async (dispatch) => {
        try{
              await axios.post(`${API_BASE_URL}/srodetails`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchSRODetails())
        }catch(error){
             console.error('Error Adding SRODetails:',error)
        }
    }
}

export const deleteSRODetails = (id) => {
    return async (dispatch) => {
        try{
            await axios.delete(`${API_BASE_URL}/srodetails/${id}`); 
            dispatch(deleteSRODetailsSuccess(id));
        }catch(error){
            console.error('Error deleting SRODetails:', error);
        }
    }
}

export const updateSRODetails = (updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/srodetails/${updateData.id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchSRODetails())
        }catch(error){
             console.error('Error Updating SRODetails:',error)
        }
    }
    
}