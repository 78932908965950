// BranchReducer.js

import { BRANCH_ID, DELETE_BRANCH, FETCH_BRANCH } from "../../Actions/MasterPage/BranchAction";

const initialState = {
    BranchData: [],
    selectedBranchID: null // Initialize selected branch ID as null
};

const BranchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BRANCH:
            const withSno = action.payload.map((data, index) => ({
                ...data,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                BranchData: withSno
            };

        case DELETE_BRANCH:
            const updatedData = state.BranchData.filter(data => data.id !== action.payload);
            const updatedDataWithSno = updatedData.map((item, index) => ({
                ...item,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                BranchData: updatedDataWithSno
            };

        case BRANCH_ID:
            return {
                ...state,
                selectedBranchID: action.payload
            };

        default:
            return state;
    }
};

export default BranchReducer;
