import React, { useEffect, useState } from "react";
import Select from "react-select"; 
import { DocumentvalidateFormDataUpdate } from "./propertyDocumentsFormValidation";
import Toast from "../../../Utils/Toast";
import { useDispatch } from "react-redux";
import {updatePropertyDocument } from "../../../Redux/Actions/MasterPage/PropertyDocumentAction";

const PropertyDocumentEdit = ({ isOpen, closeModal, editData, dropdown }) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    property_type: "",
    document: "",
    prop_status: "",
  });

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (editData) { 

      const defaultOption = dropdown.find(
        (option) => option.value === editData.prop_type
      ); 
      setSelectedOption(defaultOption);
      setFormData({
        ...editData,
        property_type: defaultOption.value,  }); 
    }
  }, [editData, dropdown]);

  const handleChangeSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFormData({
      ...formData,
      property_type: selectedOption ? selectedOption.value : "",  
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();  
    console.log("co",formData)
    const validationResult = DocumentvalidateFormDataUpdate(formData);
    if(validationResult.isValid){
      Toast({ message: "Updated successfully", type: "success" }); 
      setErrors({});
      setFormData({ document: "", status: "", type: "" });  
      setSelectedOption(null);  
      closeModal(); 
      await dispatch(updatePropertyDocument(formData)) 
    }else{
      setErrors(validationResult.errors);
    }
  };

  const handleCancel = () => {
    closeModal();
    setErrors({});
  };

  return (
    <div
      className={`modal modal-overlay ${isOpen ? "d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <h4 className="page_subheading m-3">Update Property Document</h4>
            <button
              type="button"
              className="close closebutton"
              onClick={handleCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body p-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label" htmlFor="inputState">
                    Property Type
                  </label>
                  <Select
                    value={selectedOption}
                    onChange={handleChangeSelect}
                    options={dropdown}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#e7e7e7" : "#e7e7e7",
                        fontSize: "13px",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "12px",
                        color: "black",
                      }),
                    }}
                  />
                       {errors.property_type && <div className="validation_msg">{errors.property_type}</div>}
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="document" className="form-label">
                    Property Document
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="document"
                    value={formData.document}
                    onChange={handleChange}
                  />
                    {errors.document && <div className="validation_msg">{errors.document}</div>}
                </div> 
              </div>

              <div className="mb-3 col-md-12">
                <label className="form-label" htmlFor="inputState">
                  Status
                </label>
                <select
                  className="form-select"
                  name="prop_status"
                  value={formData.prop_status}
                  onChange={handleChange}
                >
                  <option value="">--Select Status--</option>
                  <option value="Enable">Enable</option>
                  <option value="Disable">Disable</option>
                </select>

                {errors.prop_status && <div className="validation_msg">{errors.prop_status}</div>}
              </div>

             

              <div className="text-end py-3 px-3">
                <button
                  type="button"
                  className="btn1 me-1"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn1">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDocumentEdit;
