import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';    
import { fetchVillage } from '../../Redux/Actions/MasterPage/VillageAction';



export const useVillageOptions = (filter) => {

    const dispatch = useDispatch();
    const VillageData = useSelector((state) => state.Village.villageData);

    useEffect(() => {
        dispatch(fetchVillage());
    }, [dispatch]);

 


    const filterData = VillageData.filter((data) => data.village_taluk === (filter ? filter.value : ""))

    const result = filter === undefined ? VillageData : filterData

    const options = result.map((data) => ({
        value: data.id,
        label: data.village_name,
    }));

    return options;
};

const VillageDropDown = ({ onSelect,selectedVillage ,filter}) => {  
    useEffect(() => {
        if (selectedVillage) {
            onSelect(selectedVillage);
        } 
    }, [selectedVillage, onSelect]);
 
 
    const options = useVillageOptions(filter)
 
    const handleDistrictSelect = (selectedVillage) => {
        onSelect(selectedVillage);
    };

    return (
        <div> 
            <Select 
                options={options}
                onChange={handleDistrictSelect}
                value={selectedVillage}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                            ? "#e7e7e7"
                            : "#e7e7e7",
                        fontSize: "13px",
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "12px",
                        color: "black",
                    }),
                }} 
            />
        </div>
    );
};

export default VillageDropDown;
