import axios from "axios";   
import { API_BASE_URL } from "../../../Api/api";
import Toast from "../../../Utils/Toast";

export const FETCH_SOCIETY = "FETCH_SOCIETY";
export const DEL_SOCIETY = "DEL_SOCIETY";
export const DEFAULT_ID = "DEFAULT_ID"; 
export const SOCIETY_TASK_BY_STAFFID = "SOCIETY_TASK_BY_STAFFID"; 
export const FETCH_ASSIGNED_SOCIETY_TASK = "FETCH_ASSIGNED_SOCIETY_TASK"; 

 
const fetchSocietySuccess =(data)=>{
    return{
        type:FETCH_SOCIETY,
        payload:data
    }
}

const deleteSocietySuccess = (id)=>({
    type:DEL_SOCIETY,
    payload:id,
})


const successDefaultID = (id) =>({
    type :DEFAULT_ID,
    payload : id
  })

const successSocietyTaskByStaffID = (data) =>({
    type :SOCIETY_TASK_BY_STAFFID,
    payload : data
  })

 
  const successFetchSocietyAssignedTask = (data) =>({
    type : FETCH_ASSIGNED_SOCIETY_TASK,
    payload : data
  })



export const addBulkUploadSociety =(insertData)=>{
    return async ()=>{
        try{
           const response = await axios.post(`${API_BASE_URL}/society`,insertData,{
            headers: {
                'Content-Type': 'application/json'
              }
           });
          Toast({ message: "Added Successfully", type: "success" }); 
          setTimeout(() => {
            window.location.reload();
        }, 2000); 
        }catch(error){
            console.error('Error adding Bulk Upload :', error);
        }
    }
}


export const fetchDefaultID = () =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/society/${3}`);
            dispatch(successDefaultID(response.data)) 
        }
        catch(error){
            console.error("Error fetching default Id" , error)
        }
    }
  }


export const fetchSocietyReport =() =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/society`);
            dispatch(fetchSocietySuccess(response.data)) 
        }catch(error){
            console.error('Error fetching Society:', error);
        }
    }
}

export const updateSocietyReport = (updateData) =>{
    return async (dispatch) =>{
        try{
            await axios.put(`${API_BASE_URL}/society/${updateData.id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchSocietyReport());
        }catch(error){
            console.error('Error Updating Society:',error)
       }
    }
}
 
 

export const deleteSociety = (id) =>{
    return async(dispatch) =>{
        try{
            await axios.delete(`${API_BASE_URL}/society/${id}`);
            dispatch(deleteSocietySuccess(id))
        }catch(error){
            console.error('Error Deleting society:',error)
        }
    } 
}



export const getSocietyTaskByStaffID = () => {
    return async (dispatch) => {
      const staffID =  JSON.parse(sessionStorage.getItem('data')).staffID 
      try {
        const response = await axios.get(`${API_BASE_URL}/societytaskassign/${staffID}`);
         dispatch(successSocietyTaskByStaffID(response.data))
       } catch (error) {
         alert("Error fetching task", error);
      }
    };
  };




export const addSocietyTask =(insertData)=>{
    return async ()=>{
        try{
           const response = await axios.post(`${API_BASE_URL}/societytaskassign`,insertData,{
            headers: {
                'Content-Type': 'application/json'
              }
           });
          Toast({ message: "Task Assigned Successfully", type: "success" }); 
          setTimeout(() => {
            window.location.reload();
           }, 2000); 
        }catch(error){
            alert('Error task assigned :', error);
        }
    }
}



export const fetchSocietyAssignedTask =()=>{
    return async (dispatch)=>{
        try{
            const response = await axios.get(`${API_BASE_URL}/societytaskassign`);
            dispatch(successFetchSocietyAssignedTask(response.data)) 
        }catch(error){
            alert('Error fetch data :', error);
        }
    }
}



