import React from "react";
import "../footer/footercss.css";

function Footerbar() {
  return (
    <div className="footer_bar">
      <div className="text-center text-white" style={{fontSize:'12px'}}> 
        
          &copy; {new Date().getFullYear()} Goolok. All Rights
          Reserved.
     
      </div>
    </div>
  );
}

export default Footerbar;
