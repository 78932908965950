// src/redux/store.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import StaffReducer from '../Reducers/MasterPage/StaffReducer';
import PropertyTypeReducer from '../Reducers/MasterPage/PropertyTypeReducer';
import { thunk } from 'redux-thunk';
import PropertyDocumentReducer from '../Reducers/MasterPage/PropertyDocumentReducer';
import SourceBookReducer from '../Reducers/SourceBook/sourceBookReducers';
import districtReducer from '../Reducers/MasterPage/DistrictReducer';
import talukReducer from '../Reducers/MasterPage/TalukReducer';
import StateReducer from '../Reducers/MasterPage/StateReducer';
import villageReducer from '../Reducers/MasterPage/VillageReducer';
import PincodeReducer from '../Reducers/MasterPage/PincodeReducer';
import GroupTypeReducer from '../Reducers/MasterPage/GroupTypeReducer';
import BranchReducer from '../Reducers/MasterPage/BranchReducer';
import VendorReducer from '../Reducers/MasterPage/VendorReducer';
import SRODetailsReducer from '../Reducers/MasterPage/SRODetailsReducer'; 
import QualityReducer from '../Reducers/MasterPage/QualityReducer';
import StatusReducer from '../Reducers/MasterPage/StatusReducer';
import SocietyReducer from '../Reducers/Society/societyReducer';


const rootReducer = combineReducers({
  // master page data 
     PropertyType:PropertyTypeReducer,
     PropertyDocument:PropertyDocumentReducer,
     State:StateReducer,
     District : districtReducer,
     Taluk : talukReducer,
     Village : villageReducer,
     Pincode : PincodeReducer,
     Branch : BranchReducer,
     GroupType : GroupTypeReducer, 
     staff: StaffReducer,
     vendor : VendorReducer,
     SRODetails: SRODetailsReducer,
     Quality : QualityReducer,
     Status : StatusReducer,
  // source book 
     SourceBook:SourceBookReducer,
     Society:SocietyReducer,
  // other reducers
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
