import React from 'react';
import './Loader.css';  

const Loader = () => {
  return (
    <div className="loader-overlay">
       <div className="container111">
  <div className="newtons-cradle">
    <div className="newtons-cradle__dot" />
    <div className="newtons-cradle__dot" />
    <div className="newtons-cradle__dot" />
    <div className="newtons-cradle__dot" />
  </div>
</div>
    </div>
  );
};

export default Loader;
