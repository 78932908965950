import { DEL_SRO_Details, FETCH_SRO_Details } from "../../Actions/MasterPage/SRODetailsAction"

const initialState = {
    SRODetailsData : []
}

const SRODetailsReducer = (state = initialState , action) => {
  switch (action.type){
    case FETCH_SRO_Details:
        const dataWithSno = action.payload.map((item,index) => ({
            ...item,
            sno: (index + 1).toString()
        }));
        return {
            ...state,
            SRODetailsData : dataWithSno
        }

    case DEL_SRO_Details:
        const updatedData = state.SRODetailsData.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updatedData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }))
        return{
            ...state,
            SRODetailsData : updatedDataWithSno
        } 
    default:
        return state;

  }
}

export default SRODetailsReducer;