import { DEL_DISTRICT, FECTCH_DISCTRICT } from "../../Actions/MasterPage/DistrictAction"

const initialState = {
    districtData:[]
}

const districtReducer =( state=initialState , action )=>{
    switch(action.type){
        case FECTCH_DISCTRICT:
            const dataWithSno = action.payload.map((data,index)=>({ 
                ...data,
                sno: (index + 1).toString()
            }));
            return{
                districtData:dataWithSno
            }
        
        case DEL_DISTRICT:
            const updatedData = state.districtData.filter(data => data.id !== action.payload);
            const updatedDataWithSno = updatedData.map((item,index) =>({
                ...item,
                sno: (index + 1).toString()
            }))
            return{
                ...state,
                districtData : updatedDataWithSno
            } 

            default:
                return state;
    }
}


export default districtReducer;