import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import { SearchData } from "../../../Utils/Search";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import ExportButton from "../../../Utils/ExportButton";
import customStyle from "../../../Utils/tableStyle";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../Api/api";
import Select from "react-select";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { addSocietyTask } from "../../../Redux/Actions/Society/societyAction";
 
function PreviewSociety() {
  const location = useLocation();
  const dispatch = useDispatch();


  const { taskdata } = location.state || {};

  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    setFinalData(taskdata);
  }, [taskdata]);


  const [currentPage, setCurrentPage] = useState(1);


  const columns = [
    {
      name: "S.no",
      selector: (row, index) => (currentPage - 1) * 10 + index + 1,
      wrap: true,
      sortable: true,
    },
    {
      name: "Society ID",
      selector: (row) => row.project_id,
      wrap: true,
      sortable: true,
      width: "120px",
    },
    {
      name: "Society Name",
      selector: (row) => row.society_name,
      wrap: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Society Address",
      selector: (row) => row.society_address,
      wrap: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Latitude & Langitude",
      selector: (row) => row.lat_lang,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "District",
      selector: (row) => row.district,
      wrap: true,
      sortable: true, 
    },
    {
      name: "Taluk",
      selector: (row) => row.taluk,
      wrap: true,
      sortable: true, 
    },
    {
      name: "Village",
      selector: (row) => row.village,
      wrap: true,
      sortable: true, 
    }, 
    {
      name: "Contact Person 1",
      selector: (row) => row.contact1,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "Mobile Number 1",
      selector: (row) => row.mobile1,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "Contact Person 2",
      selector: (row) => row.contact2,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "Mobile Number 2",
      selector: (row) => row.mobile2,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "Contact Person 3",
      selector: (row) => row.contact3,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "Mobile Number 3",
      selector: (row) => row.mobile3,
      wrap: true,
      sortable: true,
      width: "170px",
    },
    {
      name: "No of Active Members",
      selector: (row) => row.active_member,
      wrap: true,
      sortable: true,
      width: "140px",
    },
    {
      name: "Quality",
      selector: (row) => row.quality,
      wrap: true,
      sortable: true,
    },
    {
      name: "Active status",
      selector: (row) => row.active_status,
      wrap: true,
      sortable: true,
      width: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row.remark,
      wrap: true,
      sortable: true,
      width: "250px",
    },
  ];

  // filter
  const [filters, setFilters] = useState({
    project_id: "",
    district: "",
    taluk: "",
    active_member: "",
    active_status: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredData = taskdata.filter((item) => {
      return (
        (!filters.project_id ||
          item.project_id
            .toLowerCase()
            .includes(filters.project_id.toLowerCase())) &&
        (!filters.district ||
          item.district
            .toLowerCase()
            .includes(filters.district.toLowerCase())) &&
        (!filters.taluk ||
          item.taluk.toLowerCase().includes(filters.taluk.toLowerCase())) &&
        (!filters.active_member ||
          item.active_member
            .toLowerCase()
            .includes(filters.active_member.toLowerCase())) &&
        (!filters.active_status ||
          item.active_status
            .toLowerCase()
            .includes(filters.active_status.toLowerCase()))
      );
    });

    setFinalData(filteredData);
  };

  const Clear = (e) => {
    e.preventDefault();
    setFilters({
      project_id: "",
      district: "",
      taluk: "",
      active_member: "",
      active_status: "",
    });
    setFinalData(taskdata);
  };

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [
    "project_id",
    "society_name",
    "society_address",
    "lat_lang",
    "mobile",
    "person",
    "active_member",
    "quality",
    "active_status",
    "remark",
    "contact1",
    "contact2",
    "contact3",
    "mobile1",
    "mobile2",
    "mobile3",
    "village",
    "taluk",
    "district",
  ];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(finalData, filterText, searchColumns);
  /////////////////////////////////////

  // staff
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/staff`);
        if (response.status !== 200) {
          throw new Error("Failed to fetch marker data");
        }
        const datas = response.data;
        const options = datas.map((data) => ({
          value: data.id,
          label: data.staff_name,
        }));

        setOptions(options);
      } catch (error) {
        alert("Error fetching marker data:", error.message);
      }
    };

    fetchStaff();
  }, []);

  const handleChangeSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
 
  const handleChange = ({ selectedRows }) => { 
    setSelectedTask(selectedRows);
  };
 


  let now = new Date();
  let opt = {
    timeZone: 'Asia/Kolkata',  
    hour12: false,  
    weekday: 'short', 
    year: 'numeric',  
    month: 'short',  
    day: 'numeric',  
    hour: 'numeric', 
    minute: 'numeric',  
    second: 'numeric' 
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const handleTaskSubmit =(e)=>{
      e.preventDefault()

      if (selectedTask.length === 0) {
        alert("Please select a task");
        return; 
      }
    
      if (selectedOption === null) {
        alert("Please select an staff");
        return;  
      }
      const taskSet = selectedTask.map((item)=>({
          staff_id:selectedOption.value,
          society_id:item.id,
          date:new Intl.DateTimeFormat('en-IN', opt).format(now)
      }))

     dispatch(addSocietyTask(taskSet));

     setIsButtonDisabled(true); 
      setTimeout(() => {
       setIsButtonDisabled(false);
     }, 2000); 
    }

  return (
    <>
      <Topbar />

      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Society preview Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={taskdata}
                        filename={"Society_preview_report"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row" style={{ alignItems: "end" }}>
                    <div className="col-md-6 col-lg-3 mb-3  ">
                      <label htmlFor="lastName" className="form-label">
                        Society ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="project_id"
                        value={filters.project_id}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3  ">
                      <label htmlFor="lastName" className="form-label">
                        District
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="district"
                        value={filters.district}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3  ">
                      <label htmlFor="lastName" className="form-label">
                        Taluk
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="taluk"
                        value={filters.taluk}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3  ">
                      <label htmlFor="lastName" className="form-label">
                        No of Members
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="active_member"
                        value={filters.active_member}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3  ">
                      <label htmlFor="lastName" className="form-label">
                        status
                      </label>
                      <select
                        className="form-select"
                        name="active_status"
                        value={filters.active_status}
                        onChange={handleInputChange}
                      >
                        <option value="">Select a status</option>
                        <option value="completed">Completed</option>
                        <option value="progress">In-progress</option>
                        <option value="notstarted">Not Started</option>
                      </select>
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3">
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn1"
                          onClick={handleSubmit}
                        > 
                          <FilterAltIcon /> 
                        </button>
                        <button
                          type="submit"
                          className="ms-2 btn1"
                          onClick={Clear}
                        > 
                          <RefreshIcon /> 
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3">
                      <label
                        className="form-check-label mb-2"
                        htmlFor="html"
                        style={{ fontSize: "13px" }}
                      >
                        Select Staff
                      </label>
                      <Select
                        options={options}
                        onChange={handleChangeSelect}
                        value={selectedOption}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "#e7e7e7"
                              : "#e7e7e7",
                            fontSize: "13px",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px",
                            color: "black",
                          }),
                        }}
                      />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-3">
                      <button type="submit" className="btn1" disabled={isButtonDisabled}  onClick={handleTaskSubmit}>
                        Send &nbsp;&nbsp;&nbsp;&nbsp;
                        <SendIcon />
                      </button>
                    </div>
                  </div>

                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>

                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      fixedHeader
                      persistTableHead={true}
                      selectableRows={true}
                      onSelectedRowsChange={handleChange} 
                      paginationRowsPerPageOptions={[10]} 
                      onChangePage={page => setCurrentPage(page)} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footerbar />
    </>
  );
}

export default PreviewSociety;
