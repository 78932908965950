import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";  
import "react-toastify/dist/ReactToastify.css"; 
import Topbar from "../../Components/topbar/topbar";
import Footerbar from "../../Components/footer/footer"; 
import Toast from "../../Utils/Toast";
import { useDispatch, useSelector } from "react-redux";
import { addBulkUploadSourceBook} from "../../Redux/Actions/SourceBook/sourceBookAction";
import { addBulkUploadSociety, fetchDefaultID } from "../../Redux/Actions/Society/societyAction";
import { fetchQuality } from "../../Redux/Actions/MasterPage/QualityAction";
import { fetchStatus } from "../../Redux/Actions/MasterPage/StatusAction";


function SocietyCreation() {
 
  const dispatch = useDispatch();

  const DefaultID = useSelector((state) => state.Society.SocietyDefaultID);  
  const QualityData = useSelector((state) => state.Quality.qualityData);
  const statusData = useSelector((state) => state.Status.statusData);

 
   useEffect(() => {
      dispatch(fetchDefaultID());
      dispatch(fetchQuality());
      dispatch(fetchStatus()); 
    }, [dispatch]);



 
  const [formData, setFormData] = useState({ 
    society_name:"",
    society_address:"",
    lat_lang:"",
    mobile:"",
    person:"",
    active_member:"",
    quality:"",
    active_status:"",
    remark:"",
    contact1: "",
    contact2: "",
    contact3: "",
    mobile1: "",
    mobile2: "",
    mobile3: "",
    village:"",
    taluk:"",
    district:""
  });

  useEffect(() => {
    if (DefaultID) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            project_id: `STY${DefaultID}`,
        }));
    }
}, [DefaultID]);
 

  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 

  const submitData = async(e) => {
    e.preventDefault();   
    await dispatch(addBulkUploadSociety([formData]))  
  };


  
  return (
    <>
      <Topbar /> 
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card"> 
                <div className="card-header">
                       <h4 className="page_heading">Society Creation</h4> 
                 </div>
                <div className="card-body p-3"> 

                    <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Society ID</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="project_id" 
                                readOnly
                                value={`STY${DefaultID}`}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                  Society Name
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="society_name"
                                value={formData.society_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Society Address</label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="society_address"
                                value={formData.society_address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                   
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Latitude & Langitude
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="lat_lang" 
                                value={formData.lat_lang}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 District
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="district" 
                                value={formData.district}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Taluk
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="taluk" 
                                value={formData.taluk}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Village
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="village" 
                                value={formData.village}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact1" 
                                value={formData.contact1}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 1
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile1" 
                                value={formData.mobile1}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact2" 
                                value={formData.contact2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 2
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile2" 
                                value={formData.mobile2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Contact Person 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="contact3" 
                                value={formData.contact3}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                 Mobile Number 3
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile3" 
                                value={formData.mobile3}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  


                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                No of Active Members
                              </label>
                            </div>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name="active_member" 
                                value={formData.active_member}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Quality</label>
                            </div>
                            <div className="col-7">
                            <select className="form-select"   name="quality" value={formData.quality} onChange={handleChange}> 
                              <option value="">Select a quality...</option>
                                {QualityData.map((option, index) => (
                                  <option key={index} value={option.quality}>
                                    {option.quality}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">
                                Active status
                              </label>
                            </div>
                            <div className="col-7">
                            <select className="form-select"  name="active_status"  value={formData.active_status} onChange={handleChange}> 
                                <option value="">Select a status</option>
                                <option value="completed">Completed</option>
                                <option value="progress">In-progress</option>
                                <option value="notstarted">Not Started</option> 
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group mt-3">
                          <div className="row">
                            <div className="col-4">
                              <label className="form-label">Remarks</label>
                            </div>
                            <div className="col-7">
                              <textarea 
                                type="text"
                                className="form-control"
                                name="remark" 
                                value={formData.remark}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
               
  

                        <div className="text-end mt-4 mb-4">
                          <button className="btn1 me-1" type="button">Clear</button>
                          <button type="submit" className="btn1" onClick={submitData}>
                            Add
                          </button>
                        </div>

                    </div>
                  
                  </div>
               
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerbar />
    </>
  );
}

export default SocietyCreation;
