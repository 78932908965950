import { DEL_PROPERTY_DOC, FETCH_PROPERTY_DOC } from "../../Actions/MasterPage/PropertyDocumentAction"

const initialState = {
    PropertyDocumentData : []
}

const PropertyDocumentReducer = (state = initialState , action) => {
  switch (action.type){
    case FETCH_PROPERTY_DOC:
        const dataWithSno = action.payload.map((item,index) => ({
            ...item,
            sno: (index + 1).toString() 
        }));
        return {
            ...state,
            PropertyDocumentData : dataWithSno
        }

    case DEL_PROPERTY_DOC:
        const updatedData = state.PropertyDocumentData.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updatedData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }))
        return{
            ...state,
            PropertyDocumentData : updatedDataWithSno
        } 
    default:
        return state;

  }
}

export default PropertyDocumentReducer;