import { DELETE_PINCODE, FETCH_PINCODE } from "../../Actions/MasterPage/PincodeAction"

const initialState = {
    PincodeData : []
}

const PincodeReducer = ( state= initialState, action) =>{
    switch(action.type){
        case FETCH_PINCODE:
            const withSno = action.payload.map((data,index)=>({
                ...data,
                sno: (index + 1).toString()
            }))
            return{
                PincodeData: withSno
            }
        
        case DELETE_PINCODE:
            const updatedData = state.PincodeData.filter(data => data.id !== action.payload);
            const updatedDataWithSno = updatedData.map((item,index) =>({
                ...item,
                sno: (index + 1).toString()
            })) 
            return{
                PincodeData: updatedDataWithSno
            }
         default:
            return state   
    }
}

export default PincodeReducer;