import axios from "axios";   
import { API_BASE_URL } from "../../../Api/api";
import Toast from "../../../Utils/Toast";

export const FETCH_SOURCE_BOOK = "FETCH_SOURCE_BOOK";
export const DEL_SOURCE_BOOK = "DEL_SOURCE_BOOK";
export const DEFAULT_ID = "DEFAULT_ID";
export const PROPERTY_TASK_BY_STAFFID = "PROPERTY_TASK_BY_STAFFID"; 
export const FETCH_ASSIGNED_PROPERTY_TASK = "FETCH_ASSIGNED_PROPERTY_TASK"; 


const fetchSourceBookSuccess =(data)=>{
    return{
        type:FETCH_SOURCE_BOOK,
        payload:data
    }
}

const deleteSourceBookSuccess = (id)=>({
    type:DEL_SOURCE_BOOK,
    payload:id,
})


const successDefaultID = (id) =>({
    type :DEFAULT_ID,
    payload : id
  })


  const successPropertyTaskByStaffID = (data) =>({
    type :PROPERTY_TASK_BY_STAFFID,
    payload : data
  })

  const successFetchPropertyAssignedTask = (data) =>({
    type :FETCH_ASSIGNED_PROPERTY_TASK,
    payload : data
  })
 

export const addBulkUploadSourceBook =(insertData)=>{
    return async ()=>{
        try{
           const response = await axios.post(`${API_BASE_URL}/sourcebook`,insertData,{
            headers: {
                'Content-Type': 'application/json'
              }
           }); 
        }catch(error){
            console.error('Error adding Bulk Upload :', error);
        }
    }
}


// export const UpdateBulkUploadSourceBook =(insertData)=>{
//     return async ()=>{
//         try{
//            const response = await axios.post(`${API_BASE_URL}/sourcebookbulkupdate`,insertData,{
//             headers: {
//                 'Content-Type': 'application/json'
//               }
//            });
//            if (response.status = 200) {
//             Toast({ message: "Update Successfully", type: "success" });

//             setTimeout(() => {
//                 window.location.reload();
//             }, 1000);
//         } 
//         }catch(error){
//             console.error('Error adding Bulk Upload :', error);
//         }
//     }
// }



export const fetchDefaultID = () =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/sourcebook/${3}`);
            dispatch(successDefaultID(response.data)) 
        }
        catch(error){
            console.error("Error fetching default Id" , error)
        }
    }
  }


export const fetchSourceBookReport =() =>{
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/sourcebook`);
            dispatch(fetchSourceBookSuccess(response.data)) 
        }catch(error){
            console.error('Error fetching source book report:', error);
        }
    }
}

export const updateSourceBookReport = (updateData) =>{
    return async (dispatch) =>{
        try{
            await axios.put(`${API_BASE_URL}/sourcebook/${updateData.id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchSourceBookReport())
        }catch(error){
            console.error('Error Updating source Book:',error)
       }
    }
}

export const deleteSourceBook = (id) =>{
    return async(dispatch) =>{
        try{
            await axios.delete(`${API_BASE_URL}/sourcebook/${id}`);
            dispatch(deleteSourceBookSuccess(id))
        }catch(error){
            console.error('Error Deleting source Book:',error)
        }
    }

}



export const getPropertyTaskByStaffID = () => {
    return async (dispatch) => {
      const staffID =  JSON.parse(sessionStorage.getItem('data')).staffID 
      try {
        const response = await axios.get(`${API_BASE_URL}/propertytaskassign/${staffID}`);
         dispatch(successPropertyTaskByStaffID(response.data))
       } catch (error) {
         alert("Error fetching task", error);
      }
    };
  };


export const addPropertySourceBookTask =(insertData)=>{
    return async ()=>{
        try{
           const response = await axios.post(`${API_BASE_URL}/propertytaskassign`,insertData,{
            headers: {
                'Content-Type': 'application/json'
              }
           });
          Toast({ message: "Task Assigned Successfully", type: "success" }); 
          setTimeout(() => {
            window.location.reload();
           }, 2000); 
        }catch(error){
            alert('Error task assigned :', error);
        }
    }
}

export const fetchPropertyAssignedTask =()=>{
    return async (dispatch)=>{
        try{
            const response = await axios.get(`${API_BASE_URL}/propertytaskassign`);
            dispatch(successFetchPropertyAssignedTask(response.data)) 
        }catch(error){
            alert('Error fetch data :', error);
        }
    }
}

