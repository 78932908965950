import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard/dashboard'; 

import PropertySourceBook from './Pages/PropertySourceBook/PropertySourceBook'; 
import Creation from './Pages/PropertySourceBook/Creation';
  
import Login from './Components/login/Login'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BulkUpload from './Pages/PropertySourceBook/BulkUpload';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute'; 
import MapReact from './Pages/PropertySourceBook/ReactMap/MapReact'; 

// master pages 
import PropertyType from './Pages/MasterPage/propertyType/propertType';
import PropertyDocument from './Pages/MasterPage/PropertyDocument/propertyDocument';
import SRODetails from './Pages/MasterPage/SRODetails/SRODetail';
import State from './Pages/MasterPage/State/State';
import DistrictCity from './Pages/MasterPage/District_City/DistrictCity';
import Area from './Pages/MasterPage/Taluk/area';
import Pincode from './Pages/MasterPage/Pincode/pincode';
import Village from './Pages/MasterPage/Village/village';
import Branch from './Pages/MasterPage/Branch/branch';
import GroupType from './Pages/MasterPage/GroupType/groupType';
import NewStaff from './Pages/Staff/NewStaff/newstaff';
import DisableStaff from './Pages/Staff/DisableStaff/disableStaff';
import DisableVendor from './Pages/Vendors/DisableVendor/disableVendor';
import VendorReport from './Pages/Vendors/vendorReport/vendorReport';
import NewVendors from './Pages/Vendors/NewVendors/newVendors';
import StaffReport from './Pages/Staff/StaffReport/staffReport';
import StatusCreation from './Pages/MasterPage/StatusCreation/StatusCreation';
import QualityCreation from './Pages/MasterPage/QualityCreation/QualityCreation';
import SocietyCreation from './Pages/Society/Creation';
import SocietyBulkUpload from './Pages/Society/SocietyBulkUpload';
import SocietyReport from './Pages/Society/SocietyReport';
import SocietyMapReact from './Pages/Society/ReactMap/SocietyMapReact';
import PreviewSociety from './Pages/Society/ReactMap/PreviewSociety';
import PreviewSourceBook from './Pages/PropertySourceBook/ReactMap/PreviewSourceBook';
import StaffRoute from './StaffRoute';
import staffDashboard from './Pages/Dashboard/staffDashboard';
import SocietyTasks from './Pages/StaffPages/SocietyTasks';
import PropertyTasks from './Pages/StaffPages/PropertyTasks';
import SocietyTask from './Pages/TaskReport/SocietyTask';
import PropertyTask from './Pages/TaskReport/PropertyTask';
import UpdateBulkUpload from './Pages/PropertySourceBook/UpdateBulkUpload';
import UpdateBulkUploadSociety from './Pages/Society/UpdateBulkUploadSociety';

  

function App() {
  return (
    <div>
      <BrowserRouter >
        <Routes>
          <Route exact path="/" element={<PublicRoute element={Login} />} />
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
   
          {/* master pages  */}
          <Route path="/property_type" element={<PrivateRoute element={PropertyType} />} />
          <Route path="/property_documnent" element={<PrivateRoute element={PropertyDocument} />} />
          <Route path="/SRO_Details" element={<PrivateRoute element={SRODetails} />} />
          <Route path="/state" element={<PrivateRoute element={State} />} />
          <Route path="/district_city" element={<PrivateRoute element={DistrictCity} />} />
          <Route path="/taluk" element={<PrivateRoute element={Area} />} />
          <Route path="/village" element={<PrivateRoute element={Village} />} />
          <Route path="/pincode" element={<PrivateRoute element={Pincode} />} />
          <Route path="/branch" element={<PrivateRoute element={Branch} />} />
          <Route path="/group_type" element={<PrivateRoute element={GroupType} />} />
          <Route path="/status_creation" element={<PrivateRoute element={StatusCreation} />} />
          <Route path="/quality_creation" element={<PrivateRoute element={QualityCreation} />} />

 
          {/* property source book */}
          <Route path="/creation" element={<PrivateRoute element={Creation} />} />
          <Route path="/source_book" element={<PrivateRoute element={PropertySourceBook} />} />
          <Route path="/bulk_upload" element={<PrivateRoute element={BulkUpload} />} />
          <Route path="/update_bulk_upload" element={<PrivateRoute element={UpdateBulkUpload} />} />
          <Route path='/map_view' element={<PrivateRoute element={MapReact}/>}/>
          <Route path="/preview_sourcebook" element={<PrivateRoute element={PreviewSourceBook} />} />

           
          {/* society creation  */}
          <Route path="/society_creation" element={<PrivateRoute element={SocietyCreation} />} />
          <Route path="/society_bulk_upload" element={<PrivateRoute element={SocietyBulkUpload} />} />
          <Route path="/update_bulk_upload_society" element={<PrivateRoute element={UpdateBulkUploadSociety} />} />
          <Route path="/society_report" element={<PrivateRoute element={SocietyReport} />} />
          <Route path="/society_mapview" element={<PrivateRoute element={SocietyMapReact} />} />
          <Route path="/preview_society" element={<PrivateRoute element={PreviewSociety} />} />
      

          {/* staff */}
          <Route path="/new_staff" element={<PrivateRoute element={NewStaff} />} />
          <Route path="/staff_report" element={<PrivateRoute element={StaffReport} />} />
          <Route path="/disable_staff" element={<PrivateRoute element={DisableStaff} />} />

          {/* vendors */}
          <Route path="/new_vendors" element={<PrivateRoute element={NewVendors} />} />
          <Route path="/vendor_report" element={<PrivateRoute element={VendorReport} />} />
          <Route path="/disable_vendor_report" element={<PrivateRoute element={DisableVendor} />} />
 
          
          {/* task report  */}
          <Route path="/society_task_report" element={<PrivateRoute element={SocietyTask} />} />
          <Route path="/property_task_report" element={<PrivateRoute element={PropertyTask} />} />


         {/* staff routes  */}
         <Route path="/staff_dashboard" element={<StaffRoute element={staffDashboard} />} />
         <Route path="/property_tasks" element={<StaffRoute element={PropertyTasks} />} />
         <Route path="/society_tasks" element={<StaffRoute element={SocietyTasks} />} />


        </Routes>
      </BrowserRouter>

      <ToastContainer position="top-center" autoClose={1000} />
    </div>
  );
}

export default App;
