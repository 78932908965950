import { DEL_QUALITY, FETCH_QUALITY } from "../../Actions/MasterPage/QualityAction";

const initialState = {
    qualityData: []
};

const QualityReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_QUALITY:
            const dataWithSno = action.payload.map((item, index) => ({
                ...item,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                qualityData: dataWithSno
            }; 
        case DEL_QUALITY:
            const updatedData = state.qualityData.filter(qualityData => qualityData.id !== action.payload); 
            const updatedDataWithSno = updatedData.map((item, index) => ({
                ...item,
                sno: (index + 1).toString()
            }));
            return {
                ...state,
                qualityData: updatedDataWithSno
            };
        default:
            return state;
    }
};

export default QualityReducer;
