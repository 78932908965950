import axios from "axios";
import { API_BASE_URL } from "../../../Api/api";
import Toast from "../../../Utils/Toast";

export const FETCH_QUALITY = "FETCH_QUALITY"; 
export const DEL_QUALITY = "DEL_QUALITY";

const fetchQualitySuccess = (qualityData) => ({
    type: FETCH_QUALITY,
    payload: qualityData,
});

const deleteQualitySuccess = (id) => ({
    type: DEL_QUALITY,
    payload: id,
});

// Api
export const fetchQuality = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/quality`);
            dispatch(fetchQualitySuccess(response.data));
        } catch (error) {
            console.error('Error fetching quality:', error);
        }
    };
};

export const addQuality = (insertData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${API_BASE_URL}/quality`, insertData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }); 
            dispatch(fetchQuality());
        } catch (error) {
            await Toast({ message: "Try Again", type: "warning" });
            console.error('Error adding quality creation:', error);
        }
    };
};

export const deleteQuality = (id) => {
    return async (dispatch) => {
        try {
            await axios.delete(`${API_BASE_URL}/quality/${id}`); 
            dispatch(deleteQualitySuccess(id));
        } catch (error) {
            console.error('Error deleting quality:', error);
        }
    };
};

export const updateQuality = (updateData) => {
    return async (dispatch) => {
        try {
            await axios.put(`${API_BASE_URL}/quality/${updateData.id}`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });  
            dispatch(fetchQuality());
        } catch (error) { 
            await Toast({ message: "Update failed", type: "warning" });
            console.error('Error updating quality:', error);
        }
    };
};
