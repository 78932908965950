import {FETCH_STAFF, STAFF_DEL,STAFF_ID } from "../../Actions/MasterPage/Staff";

const initialState = {
  staff: [], 
  staffID : null
};

const StaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFF:
      const withSno = action.payload.map((data,index)=>({
        ...data,
        sno: (index + 1).toString()
    }))
      return { 
        staff: withSno, 
      };
  
  
      case STAFF_DEL:
        const updatedData = state.staff.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updatedData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }))
        return {
          ...state,
          staff: updatedDataWithSno  
        };

        case STAFF_ID:
          return {
              ...state,
              staffID: action.payload
          };

    default:
      return state;
  }
};

export default StaffReducer;
