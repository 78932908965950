import Toast from "./Toast";

export const DeleteById = async (id, action, dispatch) => {
  const shouldDelete = window.confirm("Are you sure you want to delete?");
  if (shouldDelete) {
    try {
      Toast({ message: "Successfully Deleted", type: "success" }); 
      await dispatch(action(id));
    } catch (error) {
      Toast({ message: "Failed to delete item", type: "warning" });   
      console.error("Error deleting item:", error);
    }
  }
};
