import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker, InfoWindow, Circle, Autocomplete } from '@react-google-maps/api';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'; 
import Topbar from '../../../Components/topbar/topbar';
import Footerbar from '../../../Components/footer/footer'; 
import axios from 'axios'; 
import { API_BASE_URL} from '../../../Api/api';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '88vh'
};

const center = {
  lat: 13.078187,
  lng: 79.972347
};

const circleOptions = {
  strokeColor: '#3868b5',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#3868b5',
  fillOpacity: 0.35,
};

// const circleDiameter = 10000;   

const loaderOptions = {
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geometry'],
};

function SocietyMapReact() {
  const { isLoaded } = useJsApiLoader(loaderOptions);

  const [map, setMap] = useState(null);
  const [clickedLatLng, setClickedLatLng] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const autocompleteRef = useRef(null);
  const [mapMove,setMapMove] = useState(null)
  const [dia , setDia] = useState()
   

 


  const [markers, setMarkers] = useState([]);


  // all sourcing book data 
  useEffect(() => {
    const fetchMarkerData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/society`);
        if (response.status !== 200) {
          throw new Error('Failed to fetch marker data');
        }
        const datas = response.data;
        console.log("data", datas);  
        const formattedMarkers = datas?.map(data => {
          const [lat, lng] = data.lat_lang.split(',');
          return {
            ...data,
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
          };
        }); 
        setMarkers(formattedMarkers);
      } catch (error) {
        console.error('Error fetching marker data:', error.message);
      }
    };

    fetchMarkerData();
  }, []);


 


 


  const onLoad = useCallback(map => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  const handleMapClick = (event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    setClickedLatLng(latLng);
    filterMarkers(latLng);
  };

  const filterMarkers = (latLng) => {
    const markersInsideCircle = markers.filter(marker => {
      const markerPosition = new window.google.maps.LatLng(marker.position.lat, marker.position.lng);
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(new window.google.maps.LatLng(latLng), markerPosition);
      return distance <= dia;
    });
    setFilteredMarkers(markersInsideCircle); 
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseClick = () => {
    setSelectedMarker(null);
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const latLng = { lat, lng };
    //   setClickedLatLng(latLng);
      // filterMarkers(latLng);
      setMapMove(latLng)
      // if (map) {
      //   map.panTo(latLng);
      // }
    }
  };


  const HandleCirculeSet = (e) =>{
    const data = e.target.value * 1000
    setDia(data)
  }

 
  const handleSearch = () => { 
    if(mapMove){
    setClickedLatLng(mapMove);
    filterMarkers(mapMove);
    if (map) {
      map.panTo(mapMove);
    } 
    }
   
  };


  // preview page 
  const navigate = useNavigate();

  const handlePreview = () =>{
    navigate('/preview_society', { state: { taskdata: filteredMarkers } });
   }
 
  return isLoaded ? (
    <>
      <Topbar />
      <section style={{ marginTop: "57px" }}>
        <div className="container-fluid">
          <div className='row' style={{ height: "88vh" }}>
            <div className='col-sm-12 col-lg-12 ps-0 pe-0'>

          
            <div className='filter_map mt-4'>
              <div className='' style={{ width: "50%", margin: "auto" }}>
                <div className="filter_map card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 col-lg-5 mb-1 mt-1">
                        <Autocomplete
                          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                          onPlaceChanged={handlePlaceChanged}
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="location"
                            autoComplete="off"
                            placeholder='Location'
                          />
                        </Autocomplete>
                      </div>
                      <div className="col-md-12 col-lg-5 mb-1 mt-1">
                        <input
                          type="number"
                          className="form-control"
                          name="propertyName"
                          autoComplete="off"
                          placeholder='Radius - km'
                          value={dia/1000}
                          onChange={HandleCirculeSet}
                        />
                      </div>
                      <div className="col-md-6 col-lg-1 text-end mb-1 mt-1">
                        <button type="submit" className="btn1" onClick={handleSearch} >
                          <LocationSearchingIcon />
                        </button>
                      </div>

                      <div className={`col-md-6 col-lg-1 text-end mb-1 mt-1 ${filteredMarkers.length > 0 ? "d-block" : "d-none"}`}>
                      <button type="submit" className="btn1" onClick={handlePreview} >
                          <RemoveRedEyeIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={handleMapClick}
            >
              {clickedLatLng && (
                <Circle
                  center={clickedLatLng}
                  options={circleOptions}
                  radius={dia}
                />
              )}
              <MarkerClusterer minimumClusterSize={5}>
                {(clusterer) =>
                   markers.map((marker) => (
                    <Marker
                      key={marker.id}
                      position={marker.position}
                      label={marker.id}
                      clusterer={clusterer}
                      onClick={() => handleMarkerClick(marker)}
                    />
                  ))
                }
              </MarkerClusterer>
              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker.position}
                  onCloseClick={handleCloseClick}
                >
                  <div>
                    <p>Project ID : {selectedMarker.project_id}</p>
                    <p>Address:{selectedMarker.society_address}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
            </div> 
          </div>
        </div>
      </section>
      <Footerbar />
    </>
  ) : null;
}

export default SocietyMapReact;
