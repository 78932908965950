import { DELETE_VILLAGE, FETCH_VILLAGE } from "../../Actions/MasterPage/VillageAction"

const initialState = {
    villageData : []
}

const villageReducer =(state=initialState,action) =>{
    switch(action.type){
        case FETCH_VILLAGE:
            const withSno = action.payload.map((data,index)=>({
                ...data,
                sno: (index + 1).toString()
            }))
            return{
                villageData : withSno
            }

        case DELETE_VILLAGE:
            const updatedData = state.villageData.filter(data => data.id !== action.payload);
            const updatedDataWithSno = updatedData.map((item,index) =>({
                ...item,
                sno: (index + 1).toString()
            })) 
            return{
                villageData : updatedDataWithSno
            }  

            default:
                return state
    }
}

export default villageReducer;