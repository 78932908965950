import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => { 
  const isAuthenticated = sessionStorage.getItem('login_status');  
  const verification = isAuthenticated === "admin"
  return verification ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
