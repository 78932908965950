import axios from "axios"
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_VILLAGE = "FETCH_VILLAGE"
export const DELETE_VILLAGE = "DELETE_VILLAGE"

const fetchSuccess = (data) =>{
    return{
        type:FETCH_VILLAGE,
        payload : data
    }
}

const deleteSuccess =(id) =>{
    return{
        type:"DELETE_VILLAGE",
        payload:id
    }
}


export const fetchVillage =() =>{
    return async (dispatch)=>{
        try{
            const response = await axios.get(`${API_BASE_URL}/village`)
            dispatch(fetchSuccess(response.data)) 
        }catch(error){
            console.error("error fetchig village",error)
        }
    }
}

export const addVillage = (insertData) =>{
    return async (dispatch) =>{
        try{
            await axios.post(`${API_BASE_URL}/village`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
            })
           dispatch(fetchVillage())
        }catch(error){
            console.error("error adding village",error)
        }
    }
}

export const deleteVillage =(id)=>{
    return async (dispatch) =>{
        try{
             await axios.delete(`${API_BASE_URL}/village/${id}`)
             dispatch(deleteSuccess(id)) 
        }catch(error){

        }
    }

}


export const updateVillage = (id,updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/village/${id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchVillage())
        }catch(error){
             console.error('Error Updating village:',error)
        }
    }
}

