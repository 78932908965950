import '../topbar/topbar.css'
import logo from '../../Assets/images/logo.png'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import { Link, useNavigate } from 'react-router-dom';
// import { useEffect } from 'react';

function Topbar(){

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const isAuthenticated = sessionStorage.getItem('login_status'); 
  const path = isAuthenticated === "admin" ? "/dashboard" : "/staff_dashboard" 
  
  const verification = isAuthenticated === "admin"
 
 
  return(
  <>
  <nav className="navbar navbar-expand-lg " style={{position: 'fixed', top: 0, width: '100%', zIndex: 1000, backgroundColor: '#2f4f4f '}}>
  <div className="container-fluid">
    <Link to={path}><img src={logo} className="mx-3" width="170px" /></Link>
    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse mx-4" id="navbarSupportedContent">
  <ul className="navbar-nav mx-auto">
    {verification ? (
      <>
        <li className="nav-item">
          <Link to="/dashboard" className="nav-link text-white">
            Dashboard
          </Link>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Master Page
          </a>
          <ul className="dropdown-menu bg-white">
            <li><Link to="/property_type" className="dropdown-item">Property Type</Link></li>
            <li><Link to="/property_documnent" className="dropdown-item">Property Document</Link></li>
            <li><Link to="/SRO_Details" className="dropdown-item">SRO Details</Link></li>
            <li><Link to="/state" className="dropdown-item">State</Link></li>
            <li><Link to="/district_city" className="dropdown-item">District</Link></li>
            <li><Link to="/taluk" className="dropdown-item">Taluk</Link></li>
            <li><Link to="/village" className="dropdown-item">Village</Link></li>
            <li><Link to="/pincode" className="dropdown-item">Pincode</Link></li>
            <li><Link to="/branch" className="dropdown-item">Branch</Link></li>
            <li><Link to="/group_type" className="dropdown-item">Group Type</Link></li>
            <li><a className="dropdown-item" href="#">Staff Credentials</a></li>
            <li><Link to="/quality_creation" className="dropdown-item">Quality Creation</Link></li>
            <li><Link to="/status_creation" className="dropdown-item">Status Creation</Link></li>
            <li className="dropdown-submenu">
              <a className="dropdown-item dropdown-toggle" href="#">Staff</a>
              <ul className="dropdown-menu">
                <li><Link to="/new_staff" className="dropdown-item">New Staff</Link></li>
                <li><Link to="/staff_report" className="dropdown-item">Staff Report</Link></li>
                <li><Link to="/disable_staff" className="dropdown-item">Disabled Staff</Link></li>
              </ul>
            </li>
            <li className="dropdown-submenu">
              <a className="dropdown-item dropdown-toggle" href="#">Vendor</a>
              <ul className="dropdown-menu">
                <li><Link to="/new_vendors" className="dropdown-item">New Vendor</Link></li>
                <li><Link to="/vendor_report" className="dropdown-item">Vendor Report</Link></li>
                <li><Link to="/disable_vendor_report" className="dropdown-item">Disabled Report</Link></li>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Property Sourcing Book
          </a>
          <ul className="dropdown-menu bg-white">
            <li><Link to="/creation" className="dropdown-item">Property Creation</Link></li>
            <li><Link to="/bulk_upload" className="dropdown-item">Property Bulk Upload</Link></li>
            <li><Link to="/update_bulk_upload" className="dropdown-item">Property Bulk Upload Update</Link></li>
            <li><Link to="/source_book" className="dropdown-item">Property Report</Link></li>
            <li><Link to="/map_view" className="dropdown-item">Property Map View</Link></li>
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Boolok Society
          </a>
          <ul className="dropdown-menu bg-white">
            <li><Link to="/society_creation" className="dropdown-item">Society Creation</Link></li>
            <li><Link to="/society_bulk_upload" className="dropdown-item">Society Bulk Upload</Link></li>
            <li><Link to="/update_bulk_upload_society" className="dropdown-item">Society Bulk Upload Update</Link></li>
            <li><Link to="/society_report" className="dropdown-item">Society Report</Link></li>
            <li><Link to="/society_mapview" className="dropdown-item">Society Map View</Link></li>
          </ul>
        </li>


        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
             Assigned Task
          </a>
          <ul className="dropdown-menu bg-white">
            <li><Link to="/property_task_report" className="dropdown-item">Property tasks</Link></li>
            <li><Link to="/society_task_report" className="dropdown-item">Society tasks</Link></li> 
          </ul>
        </li>



      </>
    ) : (
      <>
      <li className="nav-item">
      <Link to="/staff_dashboard" className="nav-link text-white">
        Dashboard
      </Link>
    </li>
    
     
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle text-white"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Property Sourcing Book
      </a>
      <ul className="dropdown-menu bg-white">
        <li><Link to="/property_tasks" className="dropdown-item">Property Tasks</Link></li>
        {/* <li><Link to="/creation" className="dropdown-item">Completed Tasks</Link></li>  */}
      </ul>
    </li>

    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle text-white"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Boolok Society
      </a>
      <ul className="dropdown-menu bg-white">
        <li><Link to="/society_tasks" className="dropdown-item">Society Tasks</Link></li>
        {/* <li><Link to="/creation" className="dropdown-item">Completed Tasks</Link></li>  */}
      </ul>
    </li>

    </> 
    )}
  </ul>

  <form className="d-flex list-unstyled" role="search">
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle text-white"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
       {verification ? 'Admin' : JSON.parse(sessionStorage.getItem('data')).staff}</a>
      <ul className="logout dropdown-menu">
        <li><a className="dropdown-item" href="#" onClick={handleLogout}>Log out</a></li>
      </ul>
    </li>
  </form>
</div>

  </div>
</nav>

      </>
);
}
export default Topbar;