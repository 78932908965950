import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import { SearchData } from "../../Utils/Search";
import Topbar from "../../Components/topbar/topbar";
import Footerbar from "../../Components/footer/footer";
import ExportButton from "../../Utils/ExportButton"; 
import * as XLSX from 'xlsx';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux"; 
import customStyle from "../../Utils/tableStyle"; 
import SocietyBulkUploadEdit from "./SocietyBulkUploadEdit";
import { addBulkUploadSociety, fetchDefaultID } from "../../Redux/Actions/Society/societyAction";
import Loader from "../../Components/Loader/Loader";

function SocietyBulkUpload() {

  const dispatch = useDispatch();
  const fileUploadRef = useRef(null);

  const DefaultID = useSelector((state) => state.Society.SocietyDefaultID);  
  useEffect(() => {
    dispatch(fetchDefaultID()); 
  }, [dispatch]);



  const [excelData, setExcelData] = useState([]);

  
 
  const nameChange = excelData.map((item,index) => ({
    project_id: `STY${DefaultID + index}`,  
    society_name: item["Society Name"] ? item["Society Name"] : "", 
    society_address: item["Society Address"] ? item["Society Address"] : "", 
    lat_lang:item["Latitude & Langitude"] ? item["Latitude & Langitude"] : "",  
    active_member: item["No of Active Members"] ? item["No of Active Members"] : "",
    quality: item["Quality"] ? item["Quality"] : "",
    active_status: item["Active status"] ? item["Active status"] : "",
    remark: item["Remarks"] ? item["Remarks"] : "",
    contact1: item["Contact Person 1"] ? item["Contact Person 1"] : "",
    contact2: item["Contact Person 2"] ? item["Contact Person 2"] : "",
    contact3: item["Contact Person 3"] ? item["Contact Person 3"] : "",
    mobile1: item["Mobile Number 1"] ? item["Mobile Number 1"] : "",
    mobile2: item["Mobile Number 2"] ? item["Mobile Number 2"] : "",
    mobile3: item["Mobile Number 3"] ? item["Mobile Number 3"] : "",
    village: item["Village"] ? item["Village"] : "",
    taluk: item["Taluk"] ? item["Taluk"] : "",
    district: item["District"] ? item["District"] : "",  
  }));

  const [editData, setEditData] = useState("");
  const handleEdit = (row) => {
    console.log("Editing row:", row);
    setEditData(row);
  };

  const handleDelete = (row) => {
    const deleteData = excelData.filter(
      (data) => data["S.no"] !== row["S.no"]
    );
    console.log(deleteData);
    setExcelData(deleteData);
  };

 
  const updateNewData = (formData) => {
    const updatedExcelData = excelData.map((item) =>
      item["Society ID"] === formData["Society ID"] ? formData : item
    );
    setExcelData(updatedExcelData);
  };
 
  const [loading, setLoading] = useState(false); 
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setLoading(true)
    try{
      await dispatch(addBulkUploadSociety(nameChange))   
    }catch (error){
      alert("failed adding:",error)
    }finally{
      setLoading(false);
    }
    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
  }
    setExcelData([])
  };

  
  const columns = [
    {
      name: "S.no",
      selector: (row) => row["S.no"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Society ID",
      selector: (row) => row["Society ID"],
      wrap: true,
      sortable: true,
      width:"120px"
    },
    {
      name: "Society Name",
      selector: (row) => row["Society Name"],
      wrap: true,
      sortable: true,
      width:"150px"
    },
    {
      name: "Society Address",
      selector: (row) => row["Society Address"],
      wrap: true,
      sortable: true,
      width:"150px"
    },
    {
      name: "Latitude & Langitude",
      selector: (row) => row["Latitude & Langitude"],
      wrap: true,
      sortable: true,
      width:"200px"
    },
    {
      name: "District",
      selector: (row) => row["District"],
      wrap: true,
      sortable: true, 
    },
    {
      name: "Taluk",
      selector: (row) => row["Taluk"],
      wrap: true,
      sortable: true, 
    },
    {
      name: "Village",
      selector: (row) => row["Village"],
      wrap: true,
      sortable: true, 
    }, 
    {
      name: "Contact Person 1",
      selector: (row) => row["Contact Person 1"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "Mobile Number 1",
      selector: (row) => row["Mobile Number 1"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "Contact Person 2",
      selector: (row) => row["Contact Person 2"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "Mobile Number 2",
      selector: (row) => row["Mobile Number 2"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "Contact Person 3",
      selector: (row) => row["Contact Person 3"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "Mobile Number 3",
      selector: (row) => row["Mobile Number 3"],
      wrap: true,
      sortable: true,
      width:"170px"
    },
    {
      name: "No of Active Members",
      selector: (row) => row["No of Active Members"],
      wrap: true,
      sortable: true,
      width:"140px"
    },
    {
      name: "Quality",
      selector: (row) => row["Quality"],
      wrap: true,
      sortable: true,
    },
    {
      name: "Active status",
      selector: (row) => row["Active status"],
      wrap: true,
      sortable: true,
      width:"150px" 
    },
    {
      name: "Remarks",
      selector: (row) => row["Remarks"],
      wrap: true,
      sortable: true,
      width:"250px"
    }, 
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

 

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [
    "Project ID",
    "Society Name", 
    "Society Address", 
    "Latitude & Langitude", 
    "Mobile Number", 
    "Contact Person", 
    "No of Active Members", 
    "Quality", 
    "Active status", 
    "Remarks", 
  ];

  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(excelData, filterText, searchColumns);
  /////////////////////////////////////

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        
        // Convert the worksheet to JSON
        let json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

        // Get all headers from the entire sheet
        const allFields = new Set();
        json.forEach(row => {
          Object.keys(row).forEach(field => {
            allFields.add(field);
          });
        });

        // Ensure every row contains all fields
        json = json.map((obj) => {
          const newObj = {};
          allFields.forEach((field) => {
            newObj[field] = obj[field] || '';
          });
          return newObj;
        });

        console.log(json);
        setExcelData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <Topbar />
      <SocietyBulkUploadEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
        updateNewData={updateNewData}
      />

    {loading ? (
        <Loader />
      ) : (
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                  <div>
                      <h4 className="page_heading">Society Bulk Upload</h4>
                      
                  </div>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={[]}
                        type={"Upload Format"} 
                        filename={"Society_report_upload"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12 mb-4">
                    <div className=" d-flex">
                      <div>
                        <label className="form-label">Upload file : </label>
                        <input
                          type="file"
                          name="upload"
                          className="form-control mb-3"
                          id="upload"
                          ref={fileUploadRef}
                          onChange={readUploadFile}
                        />
                      </div>
                      <div className="searchbar" style={{ marginLeft: "auto" }}>
                        <input
                          type="text"
                          className="search"
                          onChange={handleFilter}
                          placeholder="..Search"
                        ></input>
                      </div>
                    </div>
                    <div className="mb-3">
                    <ExportButton
                        columns={columns}
                        data={excelData}
                        type={"Download"}
                        filename={"Society_report"}
                      />
                      </div>
                      <div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      fixedHeader
                      persistTableHead={true}
                    />
                    </div>
                    <div className="text-end py-3 px-3"> 
                      <button type="submit" className="btn1" onClick={handleSubmit}>
                       Submit
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}

      <Footerbar />
    </>
  );
}

export default SocietyBulkUpload;
