import axios from "axios";
import { API_BASE_URL } from "../../../Api/api";
import Toast from "../../../Utils/Toast";

export const FETCH_STATUS = "FETCH_STATUS";
export const DEL_STATUS = "DEL_STATUS";

const fetchStatusSuccess = (qualityData) => ({
    type: FETCH_STATUS,
    payload: qualityData,
});

const deleteStatusSuccess = (id) => ({
    type: DEL_STATUS,
    payload: id,
});

// Api
export const fetchStatus = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/status`);
            dispatch(fetchStatusSuccess(response.data));
        } catch (error) {
            console.error('Error fetching status:', error);
        }
    };
};

export const addStatus = (insertData) => {
    return async (dispatch) => {
        try {
            await axios.post(`${API_BASE_URL}/status`, insertData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }); 
            dispatch(fetchStatus());
        } catch (error) {
            await Toast({ message: "Try Again", type: "warning" });
            console.error('Error adding status creation:', error);
        }
    };
};

export const deleteStatus = (id) => {
    return async (dispatch) => {
        try {
            await axios.delete(`${API_BASE_URL}/status/${id}`); 
            dispatch(deleteStatusSuccess(id));
        } catch (error) {
            console.error('Error deleting status:', error);
        }
    };
};

export const updateStatus = (updateData) => {
    return async (dispatch) => {
        try {
            await axios.put(`${API_BASE_URL}/status/${updateData.id}`, updateData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });  
            dispatch(fetchStatus());
        } catch (error) { 
            await Toast({ message: "Update failed", type: "warning" });
            console.error('Error updating status:', error);
        }
    };
};
