import React, { useEffect, useState } from "react";
import "../mastercss.css"; 
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"; 
import ExportButton from "../../../Utils/ExportButton";
import { SearchData } from "../../../Utils/Search";
import { Tooltip as ReactTooltip } from "react-tooltip"; 
import Toast from "../../../Utils/Toast"; 
import customStyle from "../../../Utils/tableStyle"; 
import StatusCreationCreationEdit from "./StatusCreationCreationEdit";
import Topbar from "../../../Components/topbar/topbar";
import Footerbar from "../../../Components/footer/footer";
import { validateFormData } from "./StatusFormValidation";
import { useDispatch, useSelector } from "react-redux";
import { addStatus, deleteStatus, fetchStatus } from "../../../Redux/Actions/MasterPage/StatusAction";
import { DeleteById } from "../../../Utils/DeleteById";
 

function StatusCreation() { 

  const dispatch = useDispatch(); 
  const statusData = useSelector((state) => state.Status.statusData);

  useEffect(() => {
    dispatch(fetchStatus());
  }, [dispatch]);
 
  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: "date",
      selector: (row) => row.createdat,
      sortable: true,
    },
    {
      name: "Active status",
      selector: (row) => row.active_status,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn  btn-outline-info me-1 edit"
            data-tooltip-id="edit"
            onClick={() => {
              handleEdit(row);
              openModal();
            }}
          >
            <EditIcon />
          </button>
          <button
            className="btn btn-outline-danger delete"
            data-tooltip-id="delete"
            onClick={() => handleDelete(row)}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

 

  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = ["sno","status", "active_status", "createdat"];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(statusData, filterText, searchColumns);
  /////////////////////////////////////

 
 

 
  // editing modal 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };


  // dynamic 
  const [formData, setFormData] = useState({
    active_status: " ",
    status: " ",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({});
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = validateFormData(formData);

    if (validationResult.isValid) {
      console.log(formData)
      setErrors("");
      Toast({ message: "Added successfully", type: "success" });
      await dispatch(addStatus(formData));  
      setFormData({ active_status: "", status: "" });

    } else {
      setErrors(validationResult.errors);
    }
  };


  // delete
  const handleDelete = (row) => { 
    DeleteById(row.id,deleteStatus,dispatch)
    console.log("Deleting row:", row);
  };


  // edit 
  const [editData, setEditData] = useState();

  const handleEdit = (row) => {
    console.log("Editing row:", row);
    setEditData(row);
  };
  return (
    <> 
      <Topbar />
      <StatusCreationCreationEdit
        isOpen={isModalOpen}
        closeModal={closeModal}
        editData={editData}
      />
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="page_heading">Status Creation</h4>
                </div>
                <div className="card-body">
                  <form  onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-3 ">
                        <label htmlFor="lastName" className="form-label">
                          Active status
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="active_status"
                          autocomplete="off"
                          value={formData.active_status}
                          onChange={handleChange}
                        />
                             {errors.active_status && <div className="validation_msg">{errors.active_status}</div>} 
                      </div>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label" htmlFor="inputState">
                        Status
                      </label>
                      <select
                        name="status"
                        className="form-select" 
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option disable value="">
                          --Select Status--
                        </option>
                        <option value="Enable">Enable</option>
                        <option value="Disable">Disable</option>
                      </select>
                      {errors.status && <div className="validation_msg">{errors.status}</div>}
                    </div>
                  
                    <div className="text-end py-3 px-3">
                      <a
                        href="javascript:void(0);"
                        className="btn1 text-dark me-1" 
                      >
                        Clear
                      </a>
                      <button type="submit" className="btn1"> 
                        Add 
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={statusData}
                        filename={"status_data"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div>
                    <DataTable
                      columns={columns}
                      data={filterdata}
                      customStyles={customStyle}
                      pagination
                      // selectableRows
                      persistTableHead={true}
                      fixedHeader
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactTooltip
        id="edit"
        place="bottom"
        content="Edit"
        style={{ fontSize: "10px" }}
      />
      <ReactTooltip
        id="delete"
        place="bottom"
        content="Delete"
        style={{ fontSize: "10px" }}
      /> 
     <Footerbar />
    </>
  );
}

export default StatusCreation;
