import axios from 'axios';
import { API_BASE_URL } from "../../../Api/api"

export const FETCH_TALUK = "FETCH_TALUK"
export const DEL_TALUK = "DEL_TALUK"

const fetchTalukSuccess = (data) =>{
    return {
        type: FETCH_TALUK,
        payload: data
    }
}

const deleteSuccess = (id) =>{
    return {
        type: DEL_TALUK,
        payload: id
    }
}

export const fetchTaluk = () =>{
    return async (dispatch)=>{
        try{
            const response = await axios.get(`${API_BASE_URL}/taluk`)
            dispatch(fetchTalukSuccess(response.data)) 
        }catch(error){
            console.error("Error fetching taluk data:",error)
        }
    }
} 

export const addTaluk = (insertData) => {
    return async (dispatch) => {
        try{
              await axios.post(`${API_BASE_URL}/taluk`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchTaluk())
        }catch(error){
             console.error('Error Adding taluk:',error)
        }
    }
}

export const deleteTaluk = (id) => {
    return async (dispatch) => {
        try {
            await axios.delete(`${API_BASE_URL}/taluk/${id}`); 
            dispatch(deleteSuccess(id));
        } catch (error) {
            console.error('Error deleting taluk:', error);
        }
    };
}

export const updateTaluk = (id,updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/taluk/${id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchTaluk())
        }catch(error){
             console.error('Error Updating Taluk:',error)
        }
    }
}