import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import { SearchData } from "../../../src/Utils/Search";
import Topbar from "../../Components/topbar/topbar";
import Footerbar from "../../Components/footer/footer";
import ExportButton from "../../../src/Utils/ExportButton";
import { useDispatch, useSelector } from "react-redux";
import {fetchPropertyAssignedTask} from "../../Redux/Actions/SourceBook/sourceBookAction"; 
import customStyle from "../../Utils/tableStyle"; 
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import Select from "react-select";
import axios from "axios";
import { API_BASE_URL } from "../../Api/api";



function PropertyTask() {
  const dispatch = useDispatch();
  const SourceBookData = useSelector((state) => state.SourceBook.AllAssignedTaskData);
 

  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    setFinalData(SourceBookData);
  }, [SourceBookData]);
 

  useEffect(() => {
    dispatch(fetchPropertyAssignedTask());
  }, [dispatch]);
 

 

  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      wrap: true,
      sortable: true,
    },
    {
      name: "Project ID",
      selector: (row) => row.project_id,
      wrap: true,
      sortable: true,
    },
    {
        name: "Assigned staff",
        selector: (row) => row.staff_name,
        wrap: true,
        sortable: true,
        width:"150px"
      },
      
      {
        name: "Date",
        selector: (row) => row.date,
        wrap: true,
        sortable: true,
        width:"120px"
      },
    {
      name: "Project sourced by",
      selector: (row) => row.source,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Project Status",
      selector: (row) => row.project_status,
      wrap: true,
      sortable: true,
    },

    {
      name: "Date & Time",
      selector: (row) => row.source_date,
      wrap: true,
      sortable: true,
    },
    {
      name: "Source of Project",
      selector: (row) => row.source_project,
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Owned by",
      selector: (row) => row.ownedby,
      wrap: true,
      sortable: true,
    },
    {
      name: "Preference",
      selector: (row) => row.preference,
      wrap: true,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.company,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Contact Details",
      selector: (row) => row.contact,
      wrap: true,
      sortable: true,
      width: "160px",
    },
    {
      name: "Contact person 1",
      selector: (row) => row.contact1,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Mobile 1",
      selector: (row) => row.mobile1,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Contact person 2",
      selector: (row) => row.contact2,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Mobile 2",
      selector: (row) => row.mobile2,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Contact person 3",
      selector: (row) => row.contact3,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Mobile 3",
      selector: (row) => row.mobile3,
      wrap: true,
      sortable: true, 
      width:"160px"
    },
    {
      name: "Owner Ranking",
      selector: (row) => row.owner_rank,
      wrap: true,
      sortable: true,
    },
    {
      name: "Project Identified for",
      selector: (row) => row.project_identi,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Project Type",
      selector: (row) => row.project_type,
      wrap: true,
      sortable: true,
    },
    {
      name: "Approval Type",
      selector: (row) => row.approval_type,
      wrap: true,
      sortable: true,
    },
    {
      name: "Approval No.",
      selector: (row) => row.approval_on,
      wrap: true,
      sortable: true,
    },

    {
      name: "Project Condition",
      selector: (row) => row.project_condition,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      wrap: true,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      wrap: true,
      sortable: true,
    },
    {
      name: "Village",
      selector: (row) => row.village,
      wrap: true,
      sortable: true,
    },

    {
      name: "Taluk",
      selector: (row) => row.taluk,
      wrap: true,
      sortable: true,
    },

    {
      name: "District",
      selector: (row) => row.distric,
      wrap: true,
      sortable: true,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
      wrap: true,
      sortable: true,
    },
    {
      name: "Enter Village Name Manually",
      selector: (row) => row.village_manually,
      wrap: true,
      sortable: true,
      width: "250px",
    },

    {
      name: "Project Location",
      selector: (row) => row.project_loc,
      wrap: true,
      sortable: true,
      width: "200px",
    },
 
    {
      name: "Survey No",
      selector: (row) => row.survey_no,
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Capture Photos",
      selector: (row) => row.capture_photos,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Google Drive Photos & Videos",
      selector: (row) => row.google_drive,
      wrap: true,
      sortable: true,
      width: "250px",
    },

    {
      name: "Location Address",
      selector: (row) => row.location_address,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Remarks",
      selector: (row) => row.reamrk,
      wrap: true,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user,
      wrap: true,
      sortable: true,
    }, 
    {
      name: "Project Launch Year",
      selector: (row) => row.launch_year,
      wrap: true,
      sortable: true,
      width: "200px",
    }, 
    {
      name: "Total Acres",
      selector: (row) => row.total_acres,
      wrap: true,
      sortable: true,
    },
    {
      name: "Total Plots",
      selector: (row) => row.total_plots,
      wrap: true,
      sortable: true,
    },
    {
      name: "Total Saleable Plots",
      selector: (row) => row.total_sale,
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Total Sqft",
      selector: (row) => row.total_sqft,
      wrap: true,
      sortable: true,
    },

    {
      name: "Total Saleable Sqft",
      selector: (row) => row.total_salesqft,
      wrap: true,
      sortable: true,
      width: "200px",
    },

    {
      name: "Marketing Price",
      selector: (row) => row.marketing_price,
      wrap: true,
      sortable: true,
      width: "150px",
    },

    {
      name: "Selling Price",
      selector: (row) => row.selling_price,
      wrap: true,
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Blocks",
      selector: (row) => row.total_blocks,
      wrap: true,
      sortable: true,
    },
    {
      name: "A Block",
      selector: (row) => row.ablock,
      wrap: true,
      sortable: true,
    },
    {
      name: "B Block",
      selector: (row) => row.bblock,
      wrap: true,
      sortable: true,
    },
    {
      name: "C Block",
      selector: (row) => row.cblock,
      wrap: true,
      sortable: true,
    },
    {
      name: "D Block",
      selector: (row) => row.dblock,
      wrap: true,
      sortable: true,
    },
    {
      name: "E Block",
      selector: (row) => row.eblock,
      wrap: true,
      sortable: true,
    },
    {
      name: "Corner Plot",
      selector: (row) => row.corner_plot,
      wrap: true,
      sortable: true,
    },

    {
      name: "Note",
      selector: (row) => row.note,
      wrap: true,
      sortable: true, 
    },
    {
      name: "UID AB",
      selector: (row) => row.uid_ab,
      wrap: true,
      sortable: true,
    },
    {
      name: "Unique Code",
      selector: (row) => row.unique_code,
      wrap: true,
      sortable: true,
    },

    {
      name: "Guideline Value",
      selector: (row) => row.guideline,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Documentation & Patta Charges",
      selector: (row) => row.patta_charges,
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "Computer Fees",
      selector: (row) => row.computer_fees,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Booking Advance",
      selector: (row) => row.booking_advance,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Bank Details",
      selector: (row) => row.bank_details,
      wrap: true,
      sortable: true,
    },
    
    
    {
      name: "Project is located on",
      selector: (row) => row.projected_located,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from AH (km)",
      selector: (row) => row.distance_ah,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from NH (km)",
      selector: (row) => row.distance_nh,
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Distance from SH (km)",
      selector: (row) => row.distance_sh,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Distance from MDR (km)",
      selector: (row) => row.distance_mdr,
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Distance from ODR (km)",
      selector: (row) => row.distance_odr,
      wrap: true,
      sortable: true,
      width: "220px",
    },

    {
      name: "Distance from VR (km)",
      selector: (row) => row.distance_vr,
      wrap: true,
      sortable: true,
      width: "220px",
    },
    {
      name: "Have you checked the 4 Direction Developments?",
      selector: (row) => row.direction,
      wrap: true,
      sortable: true,
      width: "400px",
    },
    {
      name: "List down the Nearest Towns with Km",
      selector: (row) => row.nearest_town,
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "List down the Nearest Schools & Colleges with KM",
      selector: (row) => row.nearest_school,
      wrap: true,
      sortable: true,
      width: "400px",
    },
    {
      name: "List down the Nearest Bus, Railways & Airport with Km",
      selector: (row) => row.nearest_stops,
      wrap: true,
      sortable: true,
      width: "300px",
    },

    {
      name: "List down the Nearest Bus, Railways & Airport with Km",
      selector: (row) => row.nearest_stops,
      wrap: true,
      sortable: true,
      width: "420px",
    },

    {
      name: "List down the Nearest Hospitals with KM",
      selector: (row) => row.nearest_hospital,
      wrap: true,
      sortable: true,
      width: "320px",
    },
    {
      name: "List down the Nearest Bank with KM",
      selector: (row) => row.nearest_bank,
      wrap: true,
      sortable: true,
      width: "300px",
    },
    {
      name: "List down the Nearest SIPCOT / SEZ with KM",
      selector: (row) => row.nearest_sipcot,
      wrap: true,
      sortable: true,
      width: "350px",
    },
    {
      name: "List down the Nearest Tourism Attraction with KM",
      selector: (row) => row.nearest_tourism,
      wrap: true,
      sortable: true,
      width: "400px",
    },
     
    {
      name: "Project Data Status",
      selector: (row) => row.project_data,
      wrap: true,
      sortable: true,
      width: "200px",
    }, 

  ];



  // search function
  const [filterText, setFilterText] = useState("");
  const searchColumns = [
    "project_id",
    "source",
    "project_status",
    "source_date",
    "source_project",
    "ownedby",
    "company",
    "contact",
    "owner_rank",
    "project_identi",
    "project_type",
    "approval_type",
    "approval_on",
    "launch_year",
    "project_condition",
    "project_name",
    "village",
    "project_loc",
    "taluk",
    "distric",
    "village_manually",
    "location",
    "village_taluk",
    "total_acres",
    "total_plots",
    "total_sale",
    "cost",
    "total_blocks",
    "ablock",
    "bblock",
    "cblock",
    "dblock",
    "eblock",
    "corner_plot",
    "guideline",
    "patta_charges",
    "computer_fees",
    "booking_advance",
    "bank_details",
    "capture_photos",
    "google_drive",
    "geo_location",
    "location_address",
    "geo_link",
    "reamrk",
    "user",
    "note",
    "uid_ab",
    "unique_code",
    "projected_located",
    "distance_ah",
    "distance_nh",
    "distance_sh",
    "distance_mdr",
    "distance_odr",
    "distance_vr",
    "direction",
    "nearest_town",
    "nearest_stops",
    "nearest_hospital",
    "nearest_bank",
    "nearest_sipcot",
    "nearest_tourism",
    "project_data",
    "contact1",
    "contact2",
    "contact3",
    "mobile1",
    "mobile2",
    "mobile3",
    "preference" 
  ];
  const handleFilter = (event) => {
    setFilterText(event.target.value);
  };
  const filterdata = SearchData(finalData, filterText, searchColumns);
  /////////////////////////////////////

 
   // staff
   const [options, setOptions] = useState([]);
   const [selectedOption, setSelectedOption] = useState(null); 
 
   useEffect(() => {
     const fetchStaff = async () => {
       try {
         const response = await axios.get(`${API_BASE_URL}/staff`);
         if (response.status !== 200) {
           throw new Error("Failed to fetch marker data");
         }
         const datas = response.data;
         const options = datas.map((data) => ({
           value: data.id,
           label: data.staff_name,
         }));
 
         setOptions(options);
       } catch (error) {
         alert("Error fetching marker data:", error.message);
       }
     };
 
     fetchStaff();
   }, []);

   const handleChangeSelect = (selectedOption) => {
       setSelectedOption(selectedOption);
     };






  // filter 
  const [filters, setFilters] = useState({
    project_id: '',
    distric: '',
    taluk: '',
    total_plots: '',
    preference: '',
    year: '',
    project_status: ''
  });
 
  useEffect(()=>{
    if(selectedOption){
        setFilters({
            ...filters,
             staff:selectedOption ? selectedOption.value :""
        })
    }
  },[selectedOption])

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault(); 
    const filteredData = SourceBookData.filter((item) => {
      return (
        (!filters.project_id || item.project_id.toLowerCase().includes(filters.project_id.toLowerCase())) &&
        (!filters.distric || item.distric.toLowerCase().includes(filters.distric.toLowerCase())) &&
        (!filters.taluk || item.taluk.toLowerCase().includes(filters.taluk.toLowerCase())) &&
        (!filters.total_plots || item.total_plots.toLowerCase().includes(filters.total_plots.toLowerCase())) &&
        (!filters.preference || item.preference.toLowerCase().includes(filters.preference.toLowerCase())) &&
        (!filters.year || item.year.toLowerCase().includes(filters.year.toLowerCase())) &&
        (!filters.project_status || item.project_status.toLowerCase() === filters.project_status.toLowerCase()) &&
        (!filters.staff || item.staff_id.toLowerCase() === filters.staff.toLowerCase())
    );
    })
    setFinalData(filteredData);
  };

  const Clear = (e) => {
    e.preventDefault(); 
    setFilters({
      project_id: '',
      distric: '',
      taluk: '',
      total_plots: '',
      preference: '',
      year: '',
      project_status: ''
    });
    setFinalData(SourceBookData);
   
    
  };
  
  return (
    <>
      <Topbar /> 
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <h4 className="page_heading">Property Task Report</h4>
                    <div style={{ marginLeft: "auto" }}>
                      <ExportButton
                        columns={columns}
                        data={SourceBookData}
                        filename={"property_sourcing_book.csv"}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">

                <div className="row" style={{alignItems:"end"}}>
                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                              Project ID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="project_id" 
                              value={filters.project_id}
                              onChange={handleInputChange}
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               District
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="distric" 
                              value={filters.distric}
                              onChange={handleInputChange}
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               Taluk
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="taluk"
                              value={filters.taluk}
                              onChange={handleInputChange} 
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               No of Plots
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="total_plots" 
                              value={filters.total_plots}
                              onChange={handleInputChange}
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               Preference
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="preference" 
                              value={filters.preference}
                              onChange={handleInputChange}
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               DTCP Year
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="year" 
                              value={filters.year}
                              onChange={handleInputChange}
                            /> 
                        </div>

                        <div className="col-md-6 col-lg-3 mb-3  ">
                            <label htmlFor="lastName" className="form-label">
                               status
                            </label>
                            <select className="form-select" name="project_status" id="status" value={filters.project_status} onChange={handleInputChange}>
                                <option value="">Select a status</option>
                                <option value="completed">Completed</option>
                                <option value="progress">In-progress</option>
                                <option value="notstarted">Not Started</option>
                            </select>
                        </div>


                        <div className="col-md-6 col-lg-3 mb-3">
                      <label
                        className="form-check-label mb-2"
                        htmlFor="html"
                        style={{ fontSize: "13px" }}
                      >
                        Assigned Staff
                      </label>
                      <Select
                        options={options}
                        onChange={handleChangeSelect}
                        value={selectedOption}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "#e7e7e7"
                              : "#e7e7e7",
                            fontSize: "13px",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px",
                            color: "black",
                          }),
                        }}
                      />
                    </div>



                        <div className="col-md-6 col-lg-3 mb-3"> 
                          <div className="d-flex">
                            <button type="submit" className="btn1" onClick={handleSubmit}>  <FilterAltIcon/> </button>
                            <button type="submit" className="ms-2 btn1" onClick={Clear}> <RefreshIcon/> </button> 
                          </div>
                        </div> 
                  </div>


                  <div className="col-lg-12  mb-4">
                    <div className="searchbar">
                      <input
                        type="text"
                        className="search"
                        onChange={handleFilter}
                        placeholder="..Search"
                      ></input>
                    </div> 
                 
                      <DataTable
                        columns={columns}
                        data={filterdata}
                        customStyles={customStyle}
                        pagination
                        // selectableRows
                        fixedHeader
                        persistTableHead={true}
                      />
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footerbar />
    </>
  );
}

export default PropertyTask;
