import { ADD_STATE, DEL_STATE, FETCH_STATE, UPDATE_STATE } from "../../Actions/MasterPage/StateAction";

const initialState ={
    StateNameData: []
}

const StateReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_STATE:
            const dataWithSno = action.payload.map((data,index)=>({ 
                ...data,
                sno: (index + 1).toString()
            }));
            return { 
                StateNameData: dataWithSno
            };
        case ADD_STATE:
            return{
                ...state,
                StateNameData:[...state.StateNameData,action.payload]
            }
        case DEL_STATE:

            const updatedData = state.StateNameData.filter(data => data.id !== action.payload);
        const updatedDataWithSno = updatedData.map((item,index) =>({
            ...item,
            sno: (index + 1).toString()
        }))
            return{
                ...state,
                StateNameData: updatedDataWithSno
            }
        case UPDATE_STATE:
            return{
                ...state,
                StateNameData:state.StateNameData.map(data => data.id === action.payload.id ? action.payload : data)
            }
        default:
            return state;
    }
}

export default StateReducer;