 

  export const validateFormData = (formData) => {
    const errors = {};
    
  
    if (!formData.active_status || !formData.active_status.trim()) {
      errors.active_status = "Active status field is required";
    }  
  
    if (!formData.status || !formData.status.trim()) {
      errors.status = "Status is required";
    }
  
    if (Object.keys(errors).length > 0) {
      return { isValid: false, errors };
    }
  
    return { isValid: true, errors: {} };
  };




   
