import { DELETE_VENDOR, FETCH_VENDOR, VENDOR_ID } from "../../Actions/MasterPage/VendorAction";

const initialState = {
    vendorData:[],
    vendorID:null
}

const VendorReducer = (state=initialState ,action) =>{
    switch(action.type){
        case FETCH_VENDOR:
            const withSno = action.payload.map((data,index)=>({
                ...data,
                sno: (index + 1).toString()
            }))
            return{ 
                vendorData:withSno
            }

            case VENDOR_ID:
                return {
                    ...state,
                    vendorID: action.payload
                };

            case DELETE_VENDOR:
                const updatedData = state.vendorData.filter(data => data.id !== action.payload);
                const updatedDataWithSno = updatedData.map((item,index) =>({
                    ...item,
                    sno: (index + 1).toString()
                })) 
                return{
                    vendorData:updatedDataWithSno
                }
        default:
            return state;
    }
}

export default VendorReducer;