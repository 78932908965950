import axios from 'axios';
import { API_BASE_URL } from "../../../Api/api"

export const FECTCH_DISCTRICT = "FECTCH_DISCTRICT"
export const DEL_DISTRICT = "DEL_DISTRICT"

const fetchSuccess =(data) =>{
    return {
        type: FECTCH_DISCTRICT,
        payload: data
    }
}

const deleteSuccess = (id) => {
    return {
        type: DEL_DISTRICT,
        payload: id
    }
}

export const fetchDistrict = () => {
    return async (dispatch) =>{
        try{
            const response = await axios.get(`${API_BASE_URL}/district`) 
            dispatch(fetchSuccess(response.data)) 
        }catch(error){
            console.error("Error fetching district",error)
        }
    }
}

export const addDistrict = (insertData) => {
    return async (dispatch) => {
        try{
              await axios.post(`${API_BASE_URL}/district`,insertData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchDistrict())
        }catch(error){
             console.error('Error Adding District:',error)
        }
    }
}


export const deleteDistrict = (id) => {
    return async (dispatch) => {
        try{
            await axios.delete(`${API_BASE_URL}/district/${id}`); 
            dispatch(deleteSuccess(id));
        }catch(error){
            console.error('Error deleting District:', error);
        }
    }
}


export const updateDistrict = (id,updateData) => {
    return async (dispatch) => {
        try{
              await axios.put(`${API_BASE_URL}/district/${id}`,updateData,{
                headers: {
                    'Content-Type': 'application/json'
                  }
               });
               dispatch(fetchDistrict())
        }catch(error){
             console.error('Error Updating District:',error)
        }
    }
}